import React from 'react';

const AnimatedBackground = ({ variant = 'default' }) => {
  // Apple-inspired subtle colors
  const variants = {
    default: {
      backgroundColor: '#000000', // Pure black
      gradientFrom: 'rgba(94, 92, 230, 0.08)', // sortio-primary - blue/purple
      gradientTo: 'transparent',
      overlayOpacity: 0.05,
      gridOpacity: 0.02
    },
    alt: {
      backgroundColor: '#111111', // Very dark gray - slightly more visible
      gradientFrom: 'transparent',
      gradientTo: 'rgba(47, 197, 92, 0.1)', // sortio-secondary - green
      overlayOpacity: 0.1,
      gridOpacity: 0.03
    },
    business: {
      backgroundColor: '#000000', // Pure black
      gradientFrom: 'rgba(47, 197, 92, 0.08)', // sortio-secondary - green
      gradientTo: 'rgba(94, 92, 230, 0.05)', // sortio-primary - blue/purple
      overlayOpacity: 0.08,
      gridOpacity: 0.025
    }
  };
  
  const colors = variants[variant] || variants.default;
  
  return (
    <div className="absolute inset-0 z-[-1]">
      {/* Base color - with higher specificity force */}
      <div 
        className="absolute inset-0" 
        style={{ 
          backgroundColor: `${colors.backgroundColor} !important`,
          background: `${colors.backgroundColor} !important`
        }}
      />
      
      {/* Subtle gradient overlay */}
      <div 
        className="absolute inset-0"
        style={{ 
          background: `radial-gradient(ellipse at 30% 20%, ${colors.gradientFrom}, transparent 70%), 
                      radial-gradient(ellipse at 70% 80%, ${colors.gradientTo}, transparent 70%)`
        }}
      />
      
      {/* Fine grid pattern */}
      <div 
        className="absolute inset-0"
        style={{ 
          backgroundImage: `
            linear-gradient(to right, rgba(255, 255, 255, ${colors.gridOpacity*2}) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(255, 255, 255, ${colors.gridOpacity*2}) 1px, transparent 1px)
          `,
          backgroundSize: variant === 'default' ? '60px 60px' : '40px 40px',
          mixBlendMode: 'lighten'
        }}
      />
      
      {/* Slight light overlay in the center for focus */}
      <div
        className="absolute inset-0"
        style={{
          background: `radial-gradient(circle at center, 
                      rgba(255, 255, 255, 0.02), 
                      transparent 70%)`
        }}
      />
      
      {/* Gradient at the top */}
      <div 
        className="absolute top-0 inset-x-0 h-[1px]"
        style={{ 
          background: `linear-gradient(to right, 
                      transparent, 
                      rgba(150, 150, 150, ${colors.overlayOpacity}), 
                      transparent)`
        }}
      />
      
      {/* Gradient at the bottom */}
      <div 
        className="absolute bottom-0 inset-x-0 h-[1px]"
        style={{ 
          background: `linear-gradient(to right, 
                      transparent, 
                      rgba(150, 150, 150, ${colors.overlayOpacity}), 
                      transparent)`
        }}
      />
    </div>
  );
};

export default AnimatedBackground;