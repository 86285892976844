import React, { useState } from 'react';
import { Folder, Eye, X, Loader, RotateCcw } from 'lucide-react';
import axios from 'axios';

// FileNode Component: Represents individual files and folders
const FileNode = ({ node, level, onSelect }) => {
  // Helper function to get icon and color based on file extension
  const getFileIconAndColor = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    
    // Map of file extensions to colors (Apple-inspired, brighter)
    const extensionColors = {
      pdf: '#FF3B30',     // Red
      docx: '#5E5CE6',    // Purple/Blue (primary)
      jpg: '#FF9500',     // Orange
      jpeg: '#FF9500',    // Orange
      png: '#FF9500',     // Orange
      xlsx: '#34C759',    // Green (secondary)
      csv: '#34C759',     // Green (secondary)
      pptx: '#FF2D55',    // Pink
      mov: '#5AC8FA',     // Blue
      mp4: '#5AC8FA',     // Blue
    };
    
    // Default color if extension not found
    const color = extensionColors[extension] || '#86868B';
    
    // Return appropriate icon and color
    return { color };
  };
  
  const { color } = node.type === 'file' ? getFileIconAndColor(node.name) : { color: '#5E5CE6' };
  
  return (
    <div className="file-node">
      <div
        className={`flex items-center cursor-pointer text-apple-50 hover:bg-white/20 p-2 rounded-lg transition-all my-1 group
                   ${node.type === 'file' 
                     ? 'bg-white/15 backdrop-blur-sm border border-white/30' 
                     : 'bg-sortio-primary/10 border border-sortio-primary/20'}`}
        style={{ 
          paddingLeft: `${level * 16 + 8}px`,
          boxShadow: node.type === 'file' 
            ? '0 4px 8px rgba(0, 0, 0, 0.2), 0 0 2px rgba(255, 255, 255, 0.1)' 
            : '0 2px 4px rgba(0, 0, 0, 0.1)'
        }}
        onClick={() => onSelect(node)}
      >
        {node.type === 'folder' ? (
          <div className="flex items-center justify-center w-7 h-7 mr-3 bg-sortio-primary/30 rounded-full p-1.5 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
            </svg>
          </div>
        ) : (
          <div 
            className="flex items-center justify-center w-7 h-7 mr-3 rounded-full p-1.5" 
            style={{ 
              backgroundColor: `${color}33`, // Adding transparency
              color: color,
              boxShadow: `0 0 10px ${color}33` 
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
              <polyline points="14 2 14 8 20 8"></polyline>
            </svg>
          </div>
        )}
        <span className={`text-sm group-hover:text-white transition-colors ${node.type === 'file' ? 'font-medium text-white' : 'font-medium'}`}>{node.name}</span>
      </div>
      {node.type === 'folder' && node.children && (
        <div>
          {node.children.map((child) => (
            <FileNode key={child.id} node={child} level={level + 1} onSelect={onSelect} />
          ))}
        </div>
      )}
    </div>
  );
};

// FileExplorer Component: Displays the entire filesystem with high visibility
const FileExplorer = ({ fileSystem, onSelect }) => {
  return (
    <div className="file-explorer h-full overflow-auto custom-scrollbar pr-2 rounded-lg p-3 border-2 border-white/30"
      style={{ 
        background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.15) 0%, rgba(90, 90, 150, 0.12) 100%)',
        boxShadow: 'inset 0 2px 8px rgba(0,0,0,0.4), 0 0 30px rgba(94, 92, 230, 0.3), 0 0 10px rgba(255, 255, 255, 0.1)'
      }}>
      {fileSystem.map((node) => (
        <FileNode key={node.id} node={node} level={0} onSelect={onSelect} />
      ))}
    </div>
  );
};

// FolderSelector Component: Modal for selecting a folder
const FolderSelector = ({ isOpen, onClose, fileSystem, onSelect }) => {
  if (!isOpen) return null;

  const renderFolders = (folders) => {
    return folders.map((node) => (
      <div key={node.id}>
        {node.type === 'folder' && (
          <button
            className="w-full text-left mb-3 p-4 bg-apple-600/30 text-apple-50 rounded-xl 
                      border border-apple-600/30 hover:bg-sortio-primary/10 hover:border-sortio-primary/30 
                      transition-all duration-300 group"
            onClick={() => {
              onSelect(node);
              onClose();
            }}
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 w-8 h-8 rounded-full bg-apple-700/60 flex items-center justify-center mr-3 
                          group-hover:bg-sortio-primary/30 transition-colors duration-300">
                <Folder className="text-apple-300 group-hover:text-sortio-primary transition-colors" size={16} />
              </div>
              <span className="font-medium group-hover:text-sortio-primary transition-colors">{node.name}</span>
            </div>
          </button>
        )}
        {node.children && node.children.length > 0 && (
          <div className="ml-5 pl-4 border-l border-apple-600/30">
            {renderFolders(node.children)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="fixed inset-0 bg-black/90 backdrop-blur-md flex items-center justify-center z-50 animate-fadeIn">
      <div 
        className="relative max-w-lg w-full"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Glow effect */}
        <div className="absolute -inset-0.5 bg-gradient-to-r from-sortio-primary/30 to-sortio-secondary/30 rounded-2xl blur-md opacity-50"></div>
        
        {/* Modal content */}
        <div className="relative bg-apple-700/70 backdrop-blur-2xl p-8 rounded-2xl shadow-2xl border border-apple-600/30"
             style={{
               boxShadow: '0 20px 60px -20px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.4)'
             }}>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-medium text-apple-50">Select a Folder</h2>
            <button 
              onClick={onClose}
              className="text-apple-300 hover:text-apple-50 p-2 rounded-full hover:bg-apple-600/40 transition-colors"
            >
              <X size={18} />
            </button>
          </div>
          
          <div className="h-px bg-gradient-to-r from-transparent via-apple-600/40 to-transparent mb-6"></div>
          
          <p className="text-apple-200 mb-6">Choose a folder from the file system to organize with AI.</p>
          
          <div className="bg-apple-600/20 border border-apple-600/30 rounded-xl p-4 max-h-[400px] overflow-auto custom-scrollbar">
            {renderFolders(fileSystem)}
          </div>
          
          <div className="mt-8 flex justify-end gap-4">
            <button
              className="py-3 px-6 rounded-xl bg-apple-600/40 text-apple-200 hover:text-apple-50 hover:bg-apple-600/60 transition-all duration-300"
              onClick={onClose}
            >
              Cancel
            </button>
            
            <button
              className="py-3 px-6 rounded-xl bg-gradient-to-r from-sortio-primary to-sortio-primary/80 text-white font-medium
                        shadow-lg shadow-sortio-primary/20 hover:shadow-sortio-primary/30 hover:brightness-110 transition-all duration-300"
              onClick={onClose}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main DemoSection Component
const DemoSection = () => {
  const initialFileSystem = [
    {
      id: 1,
      name: 'My Documents',
      type: 'folder',
      path: 'My Documents',
      children: [
        { id: 2, name: 'tax_return_2022.pdf', type: 'file', path: 'My Documents/tax_return_2022.pdf' },
        { id: 3, name: 'w2_2022.pdf', type: 'file', path: 'My Documents/w2_2022.pdf' },
        { id: 4, name: 'family_photo_2023.jpg', type: 'file', path: 'My Documents/family_photo_2023.jpg' },
        { id: 5, name: 'resume_2023.docx', type: 'file', path: 'My Documents/resume_2023.docx' },
        { id: 6, name: 'project_proposal.pptx', type: 'file', path: 'My Documents/project_proposal.pptx' },
        { id: 7, name: 'budget_2023.xlsx', type: 'file', path: 'My Documents/budget_2023.xlsx' },
        { id: 8, name: 'vacation_itinerary.pdf', type: 'file', path: 'My Documents/vacation_itinerary.pdf' },
        { id: 9, name: 'home_inventory.csv', type: 'file', path: 'My Documents/home_inventory.csv' },
        { id: 10, name: 'birthday_party.mov', type: 'file', path: 'My Documents/birthday_party.mov' },
        { id: 11, name: 'work_contract.pdf', type: 'file', path: 'My Documents/work_contract.pdf' }
      ]
    }
  ];

  const [fileSystem, setFileSystem] = useState(initialFileSystem);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [sortingPrompt, setSortingPrompt] = useState('');
  const [isSorting, setIsSorting] = useState(false);
  const [sorted, setSorted] = useState(false);
  const [isFolderSelectorOpen, setIsFolderSelectorOpen] = useState(false);
  const [sortingPlan, setSortingPlan] = useState(null);
  const [originalFileSystem, setOriginalFileSystem] = useState(null);

  const promptExamples = [
    'Sort by file type and project',
    'Organize by date and importance',
    'Categorize work, personal, and media files',
  ];

  const handleSelectFolder = () => {
    setIsFolderSelectorOpen(true);
  };

  const handleFolderSelection = (folder) => {
    setSelectedFolder(folder);
  };

  const handlePromptChange = (e) => {
    setSortingPrompt(e.target.value);
  };

  const handleSortClick = async () => {
    if (selectedFolder && sortingPrompt.trim() !== '') {
      setIsSorting(true);

      const filenames = getFilenamesInFolder(selectedFolder);
      const files = filenames.map(file => ({
        name: file,
        creationDate: new Date().toISOString() // Using current date for demo purposes
      }));

      try {
        const response = await axios.post('https://app.getsortio.com/api/sorting-plan/', {
          files: files,
          sorting_prompt: sortingPrompt
        }, {
          headers: {
            'Authorization': `Bearer DEMO`,
            'Content-Type': 'application/json'
          }
        });

        const result = response.data;

        if (!result.newFolders || !Array.isArray(result.newFolders) || 
            !result.sortingPlan || !Array.isArray(result.sortingPlan)) {
          throw new Error('Invalid response structure from API');
        }

        setSortingPlan(result);
        setIsSorting(false);
        setSorted(true);

        // Store the original file system before applying changes
        setOriginalFileSystem(JSON.parse(JSON.stringify(fileSystem)));

        // Update the file system based on the sorting plan
        const updatedFileSystem = applySortingPlan(fileSystem, selectedFolder.path, result);
        setFileSystem(updatedFileSystem);

        // Clear selections after sorting
        setSelectedFolder(null);
        setSortingPrompt('');
      } catch (error) {
        console.error('Error generating sorting plan:', error);
        setIsSorting(false);
      }
    }
  };

  const applySortingPlan = (currentFileSystem, basePath, plan) => {
    const updatedFileSystem = JSON.parse(JSON.stringify(currentFileSystem)); // Deep copy

    const findFolder = (path) => {
      const parts = path.split('/');
      let current = updatedFileSystem[0]; // Start from the root

      for (let i = 1; i < parts.length; i++) {
        const part = parts[i];
        const found = current.children.find(child => child.name === part && child.type === 'folder');
        if (!found) {
          const newFolder = {
            id: Date.now() + Math.random(),
            name: part,
            type: 'folder',
            path: parts.slice(0, i + 1).join('/'),
            children: []
          };
          current.children.push(newFolder);
          current = newFolder;
        } else {
          current = found;
        }
      }
      return current;
    };

    // Create new folders
    plan.newFolders.forEach(folderName => {
      findFolder(`${basePath}/${folderName}`);
    });

    // Move files according to the sorting plan
    plan.sortingPlan.forEach(item => {
      const sourceFolder = findFolder(basePath);
      const targetFolder = findFolder(`${basePath}/${item.targetFolder}`);
      
      const fileIndex = sourceFolder.children.findIndex(child => child.name === item.file && child.type === 'file');
      if (fileIndex !== -1) {
        const [file] = sourceFolder.children.splice(fileIndex, 1);
        file.path = `${targetFolder.path}/${file.name}`;
        targetFolder.children.push(file);
      }
    });

    return updatedFileSystem;
  };

  const handleRevert = () => {
    if (originalFileSystem) {
      setFileSystem(originalFileSystem);
      setOriginalFileSystem(null);
      setSorted(false);
      setSortingPlan(null);
    }
  };

  const handleCancel = () => {
    setSelectedFolder(null);
    setSortingPrompt('');
    setSorted(false);
    setSortingPlan(null);
  };

  const getFilenamesInFolder = (folder) => {
    const filenames = [];
    const traverseFolder = (node) => {
      if (node.type === 'file') {
        filenames.push(node.name);
      } else if (node.type === 'folder' && node.children) {
        node.children.forEach(traverseFolder);
      }
    };
    traverseFolder(folder);
    return filenames;
  };

  return (
    <section id="demo" className="py-32 sm:py-40" style={{ background: "linear-gradient(180deg, #000000 0%, #0D0D0D 100%)" }}>
      {/* Section separator */}
      <div className="absolute top-0 inset-x-0 h-px bg-gradient-to-r from-transparent via-apple-600/40 to-transparent"></div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-24">
          <h2 className="text-4xl md:text-5xl font-semibold mb-5 tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-sortio-primary via-white to-sortio-secondary">
            Experience the Magic
          </h2>
          <p className="text-lg text-apple-300 max-w-2xl mx-auto font-light">
            See how Sortio transforms chaos into order with a simple prompt
          </p>
        </div>
        
        {/* Demo Background with Highly Visible Gradient */}
        <div className="relative">
          {/* Enhanced prominent glow effects */}
          <div className="absolute inset-0 bg-gradient-to-b from-sortio-primary/70 to-transparent rounded-[32px] blur-3xl -z-10 opacity-90"></div>
          <div className="absolute inset-0 bg-gradient-to-tr from-apple-50/30 via-sortio-secondary/30 to-sortio-primary/40 rounded-[32px] blur-xl -z-10"></div>
          <div className="absolute inset-0 bg-gradient-to-r from-sortio-primary/30 to-sortio-secondary/30 rounded-[32px] blur-2xl -z-10 animate-pulse-slow"></div>
          
          {/* Background frame for extra contrast */}
          <div className="absolute -inset-2 rounded-[36px] bg-white/10 backdrop-blur-sm"></div>
          
          {/* White outer border for separation from background */}
          <div className="relative p-[3px] rounded-[32px] bg-gradient-to-r from-white/30 via-sortio-secondary/40 to-sortio-primary/40">
            {/* Mac-Style Window Container with high contrast */}
            <div className="relative bg-apple-700/80 backdrop-blur-2xl border-2 border-white/20 rounded-[28px] p-1 overflow-hidden shadow-2xl"
                 style={{ boxShadow: '0 50px 100px -20px rgba(0, 0, 0, 0.3), 0 30px 60px -30px rgba(0, 0, 0, 0.5), 0 0 60px 15px rgba(94, 92, 230, 0.4)' }}>
            
              {/* Mac Window Header - Brightened */}
              <div className="bg-apple-700/90 backdrop-blur-md px-4 py-3 flex items-center border-b border-apple-600/30 rounded-t-[24px]">
                <div className="flex space-x-2">
                  <div className="w-3 h-3 rounded-full bg-[#FF5F57]"></div>
                  <div className="w-3 h-3 rounded-full bg-[#FEBC2E]"></div>
                  <div className="w-3 h-3 rounded-full bg-[#28C840]"></div>
                </div>
                <div className="mx-auto text-apple-50 text-sm font-medium">Sortio File Organizer</div>
              </div>
              
              {/* Content Grid with high contrast */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-1 bg-apple-800/70 p-1 rounded-b-[24px]">
                {/* Left Side: File Explorer - Brighter panel */}
                <div className="relative p-6 border-r border-white/20"
                  style={{
                    background: 'linear-gradient(135deg, rgba(20, 20, 25, 0.8) 0%, rgba(30, 30, 40, 0.9) 100%)',
                    boxShadow: 'inset 0 0 30px rgba(0, 0, 0, 0.4), 0 0 30px rgba(94, 92, 230, 0.15)'
                  }}>
                  <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center space-x-3">
                      <div className="w-2 h-7 bg-gradient-to-b from-sortio-primary to-sortio-primary/70 rounded-full"></div>
                      <h3 className="text-xl font-medium text-white">Files</h3>
                    </div>
                    
                    {sorted && (
                      <span className="flex items-center text-sortio-secondary text-xs font-medium px-4 py-2 rounded-full bg-sortio-secondary/20 border-2 border-sortio-secondary/40 shadow-lg shadow-sortio-secondary/20">
                        <span className="w-2 h-2 rounded-full bg-sortio-secondary mr-2 animate-pulse"></span>
                        Organized
                      </span>
                    )}
                  </div>
                  
                  <div className="relative h-[520px] rounded-xl overflow-hidden">
                    <div className="absolute inset-0 bg-gradient-to-br from-white/10 to-sortio-primary/5 rounded-xl"></div>
                    <div className="absolute inset-0 bg-gradient-to-tr from-sortio-primary/5 to-transparent rounded-xl animate-pulse-slow"></div>
                    <FileExplorer fileSystem={fileSystem} onSelect={() => {}} />
                    
                    {/* Enhanced highlight effect for sorted state */}
                    {sorted && (
                      <div className="absolute inset-0 bg-sortio-secondary/15 pointer-events-none border-2 border-sortio-secondary/40 rounded-xl"></div>
                    )}
                  </div>
                  
                  {/* Enhanced glow effect when sorted */}
                  {sorted && (
                    <div className="absolute -inset-2 bg-sortio-secondary/30 rounded-3xl blur-xl -z-10 opacity-50"></div>
                  )}
                </div>

                {/* Right Side: Sortio App UI - Brighter panel */}
                <div className="p-6"
                  style={{
                    background: 'linear-gradient(135deg, rgba(30, 30, 40, 0.9) 0%, rgba(20, 20, 25, 0.8) 100%)',
                    boxShadow: 'inset 0 0 30px rgba(0, 0, 0, 0.4), 0 0 30px rgba(47, 197, 92, 0.15)'
                  }}>
                  <div className="flex items-center space-x-3 mb-6">
                    <div className="w-2 h-7 bg-gradient-to-b from-sortio-secondary to-sortio-secondary/70 rounded-full"></div>
                    <h3 className="text-xl font-medium text-white">AI Organization</h3>
                  </div>
                  
                  <div className="space-y-6">
                    {/* Select Folder Button - Highly visible */}
                    <button
                      onClick={handleSelectFolder}
                      className={`group flex items-center justify-center py-5 px-6 rounded-xl w-full transition-all duration-300 ${
                        selectedFolder
                          ? 'bg-white/15 hover:bg-white/20 text-white border-2 border-white/20'
                          : 'bg-gradient-to-r from-sortio-primary to-sortio-primary/80 text-white border-2 border-sortio-primary/30'
                      }`}
                      style={{
                        boxShadow: selectedFolder 
                          ? '0 0 10px rgba(255, 255, 255, 0.1)' 
                          : '0 0 25px 5px rgba(94, 92, 230, 0.5), 0 4px 15px rgba(94, 92, 230, 0.5)'
                      }}
                    >
                      <Folder className={`mr-3 ${selectedFolder ? '' : 'animate-pulse-slow'}`} size={22} />
                      <span className="font-medium text-lg">{selectedFolder ? 'Change Folder' : 'Select Folder'}</span>
                    </button>

                    {/* Selected Folder Display - Enhanced */}
                    {selectedFolder && (
                      <div className="mt-5 p-5 bg-white/10 rounded-xl border-2 border-white/20 backdrop-blur-sm">
                        <p className="text-white text-sm flex items-center">
                          <span className="w-2 h-2 rounded-full bg-sortio-primary mr-2"></span>
                          Selected: <span className="text-white font-medium ml-1">{selectedFolder.path}</span>
                        </p>
                      </div>
                    )}

                    {/* Prompt Input Section - Enhanced */}
                    <div className="space-y-3">
                      <label className="block text-sm text-white font-medium">Tell Sortio how to organize your files</label>
                      <input
                        type="text"
                        value={sortingPrompt}
                        onChange={handlePromptChange}
                        placeholder="e.g., Sort by file type and project..."
                        className={`w-full py-5 px-5 bg-white/15 text-white rounded-xl border-2 border-white/20
                          focus:outline-none focus:ring-2 focus:ring-sortio-primary/70 focus:border-sortio-primary/70 
                          transition-all duration-300 placeholder-white/50 text-lg`}
                        style={{
                          boxShadow: (selectedFolder && sortingPrompt.trim() === '') 
                            ? '0 0 20px 5px rgba(94, 92, 230, 0.3), 0 4px 15px rgba(94, 92, 230, 0.3)'
                            : '0 0 10px rgba(255, 255, 255, 0.05)'
                        }}
                      />
                    </div>

                    {/* Prompt Examples - Enhanced */}
                    <div className="flex flex-wrap gap-2">
                      {promptExamples.map((prompt, index) => (
                        <button
                          key={index}
                          onClick={() => setSortingPrompt(prompt)}
                          className={`group bg-white/10 border-2 border-white/20 hover:border-sortio-primary/70 
                            hover:bg-sortio-primary/20 text-white hover:text-white py-2.5 px-5 
                            rounded-full text-sm transition-all duration-300 ${
                              selectedFolder && sortingPrompt.trim() === '' ? 'animate-pulse-slow' : ''
                            }`}
                          style={{
                            boxShadow: selectedFolder && sortingPrompt.trim() === '' 
                              ? '0 0 10px 2px rgba(94, 92, 230, 0.25)' 
                              : '0 0 5px rgba(255, 255, 255, 0.05)'
                          }}
                        >
                          <span className="relative">
                            {prompt}
                            <span className="absolute inset-x-0 bottom-0 h-0.5 bg-sortio-primary scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-300"></span>
                          </span>
                        </button>
                      ))}
                    </div>

                    {/* Action Buttons - Enhanced */}
                    <div className="flex justify-between gap-4 pt-3">
                      <button
                        onClick={handleSortClick}
                        disabled={!selectedFolder || sortingPrompt.trim() === '' || isSorting}
                        className={`group flex items-center justify-center py-5 px-6 rounded-xl transition-all duration-300 flex-1 ${
                          (!selectedFolder || sortingPrompt.trim() === '' || isSorting)
                            ? 'bg-white/5 text-white/40 cursor-not-allowed border-2 border-white/10'
                            : 'bg-gradient-to-r from-sortio-secondary to-sortio-secondary/80 text-white border-2 border-sortio-secondary/30'
                        }`}
                        style={{
                          boxShadow: (!selectedFolder || sortingPrompt.trim() === '' || isSorting)
                            ? 'none'
                            : '0 0 25px 5px rgba(47, 197, 92, 0.5), 0 4px 15px rgba(47, 197, 92, 0.5)'
                        }}
                      >
                        {isSorting ? (
                          <Loader className="animate-spin mr-2" size={22} />
                        ) : (
                          <>
                            <Eye className={`mr-3 ${(!selectedFolder || sortingPrompt.trim() === '' || isSorting) ? '' : 'animate-pulse-slow'}`} size={22} />
                            <span className="font-medium text-lg">Organize Files</span>
                          </>
                        )}
                      </button>
                      
                      {sorted && (
                        <button
                          onClick={handleRevert}
                          className="flex items-center justify-center py-5 px-6 rounded-xl transition-all duration-300
                          bg-white/10 border-2 border-white/20 text-white hover:text-white hover:bg-white/15"
                          style={{
                            boxShadow: '0 0 10px rgba(255, 255, 255, 0.05)'
                          }}
                        >
                          <RotateCcw className="mr-2" size={20} />
                          <span className="font-medium">Reset</span>
                        </button>
                      )}
                    </div>

                    {/* Success Message - Enhanced */}
                    {sorted && (
                      <div className="p-5 rounded-xl bg-sortio-secondary/20 border-2 border-sortio-secondary/50 shadow-lg shadow-sortio-secondary/20">
                        <p className="text-white flex items-center">
                          <span className="w-3 h-3 rounded-full bg-sortio-secondary mr-3 animate-pulse"></span>
                          <span>Sorting completed! Your files have been organized according to your instructions.</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FolderSelector
        isOpen={isFolderSelectorOpen}
        onClose={() => setIsFolderSelectorOpen(false)}
        fileSystem={fileSystem}
        onSelect={handleFolderSelection}
      />
    </section>
  );
};

export default DemoSection;