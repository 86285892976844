import React from 'react';
import { DollarSign, Check } from 'lucide-react';

const PricingTier = ({ title, subtitle, price, annualPrice, isAnnual, features, popular, color = "sortio-secondary" }) => (
  <div className={`bg-apple-700/40 backdrop-blur-md p-8 rounded-3xl border border-apple-600/30 ${popular ? 'ring-2 ring-' + color : ''} relative overflow-hidden transition-all duration-500 hover:translate-y-[-8px]`}>
    {popular && (
      <div className={`absolute -top-3 -right-3 bg-${color} text-white px-3 py-1 rounded-full text-xs font-medium`}>
        {/* POPULAR */}
      </div>
    )}
    
    {/* Colored gradient orb in background */}
    <div className={`absolute -left-20 -bottom-20 w-40 h-40 rounded-full bg-${color}/20 opacity-30 blur-3xl`}></div>
    
    <h3 className="text-xl font-medium mb-2 text-apple-50">{title}</h3>
    <p className="text-apple-300 mb-4">{subtitle}</p>
    
    <div className="mb-6">
      <span className="text-4xl font-bold text-apple-50">{isAnnual ? annualPrice : price}</span>
      <span className="text-apple-300 ml-1">per user/{isAnnual ? 'year' : 'month'}</span>
      {isAnnual && <div className="text-xs text-sortio-secondary mt-1">Save 20% with annual billing</div>}
    </div>
    
    <ul className="space-y-3 mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start">
          <Check className={`h-5 w-5 text-${color} mr-2 mt-0.5 flex-shrink-0`} />
          <span className="text-apple-300">{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

const BusinessPricing = () => {
  const [isAnnual, setIsAnnual] = React.useState(false);
  
  const toggleBilling = () => {
    setIsAnnual(!isAnnual);
  };

  return (
    <section id="pricing" className="mb-16">
      {/* Section header */}
      <div className="text-center mb-12">
        <div className="inline-block mb-4">
          <div className="flex items-center justify-center p-1.5 rounded-full bg-sortio-primary/10 px-4 py-1.5">
            <DollarSign className="mr-2 text-sortio-primary" size={14} />
            <span className="text-xs font-medium uppercase tracking-wider text-sortio-primary">Simple Pricing</span>
          </div>
        </div>
        <h2 className="text-4xl font-semibold mb-5 tracking-tight text-apple-50">
          Business <span className="apple-gradient-text">Pricing</span>
        </h2>
        <p className="text-apple-300 text-lg mx-auto font-light max-w-2xl mb-5">
          Straightforward pricing plans designed to scale with your organization
        </p>
        
        {/* Billing toggle */}
        <div className="flex items-center justify-center mb-10 space-x-2">
          <span className={`text-sm ${!isAnnual ? 'text-apple-50 font-medium' : 'text-apple-300'}`}>Monthly</span>
          <button 
            onClick={toggleBilling}
            className="relative inline-flex h-6 w-12 items-center rounded-full bg-apple-700"
            aria-pressed={isAnnual}
          >
            <span className="sr-only">Toggle billing frequency</span>
            <span 
              className={`${
                isAnnual ? 'translate-x-6 bg-sortio-primary' : 'translate-x-1 bg-apple-300'
              } inline-block h-4 w-4 transform rounded-full transition-transform duration-200 ease-in-out`}
            />
          </button>
          <span className={`text-sm ${isAnnual ? 'text-apple-50 font-medium' : 'text-apple-300'}`}>Annual</span>
          {isAnnual && <span className="inline-block text-xs font-medium text-sortio-secondary px-2 py-0.5 rounded-full bg-sortio-secondary/10">Save 20%</span>}
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <PricingTier 
          title="Starter"
          subtitle="1-10 users"
          price="$25"
          annualPrice="$240"
          isAnnual={isAnnual}
          features={[
            "All core features",
            "Basic admin console",
            "Email support",
            "AI-powered sorting"
          ]}
          color="sortio-secondary"
        />
        
        <PricingTier 
          title="Business"
          subtitle="11-50 users"
          price="$20"
          annualPrice="$192"
          isAnnual={isAnnual}
          features={[
            "All Starter features",
            "Advanced admin console",
            "Custom rule creation",
            "Priority support"
          ]}
          popular={true}
          color="sortio-primary"
        />
        
        <PricingTier 
          title="Professional"
          subtitle="51-200 users"
          price="$15"
          annualPrice="$144"
          isAnnual={isAnnual}
          features={[
            "All Business features",
            "Advanced API access",
            "Dedicated support rep",
            "User-level analytics"
          ]}
          color="sortio-secondary"
        />
      </div>
      
      <div className="mt-10 bg-apple-700/30 backdrop-blur-md p-8 rounded-3xl border border-apple-600/30 text-center relative overflow-hidden">
        {/* Subtle gradient background */}
        <div className="absolute inset-0 bg-gradient-to-br from-sortio-primary/5 to-sortio-secondary/5 opacity-30"></div>
        
        <h3 className="text-2xl font-medium mb-3 text-apple-50">Enterprise</h3>
        <p className="text-apple-300 mb-2 max-w-2xl mx-auto">
          201+ users
        </p>
        <p className="text-apple-300 mb-6 max-w-2xl mx-auto">
          Custom pricing and features tailored to your organization's needs.
        </p>
        <a href="#waitlist" className="inline-block bg-gradient-to-r from-sortio-primary to-sortio-primary/80 text-white font-medium px-8 py-3 text-base rounded-xl shadow-lg shadow-sortio-primary/20 hover:shadow-sortio-primary/30 hover:brightness-110 transition-all duration-300">
          Contact Sales
        </a>
      </div>
    </section>
  );
};

export default BusinessPricing;