import React from 'react';
import { Shield, Lock, Database, Building } from 'lucide-react';

const SecurityFeature = ({ icon: Icon, title, description }) => (
  <div className="apple-panel backdrop-blur-xl p-8 rounded-3xl relative overflow-hidden group transition-all duration-500 hover:translate-y-[-5px]">
    {/* Colored gradient orb in background */}
    <div className="absolute -right-20 -top-20 w-40 h-40 rounded-full bg-sortio-secondary opacity-10 blur-3xl group-hover:opacity-20 transition-opacity duration-500"></div>
    
    {/* Icon with colored background */}
    <div className="inline-flex items-center justify-center w-12 h-12 rounded-2xl bg-sortio-secondary bg-opacity-20 mb-6">
      <Icon className="text-sortio-secondary opacity-90" size={24} />
    </div>
    
    <h3 className="text-xl font-medium mb-3 text-apple-50">{title}</h3>
    <p className="text-apple-300 text-base leading-relaxed">{description}</p>
    
    {/* Subtle line separator */}
    <div className="h-px w-16 bg-gradient-to-r from-transparent via-apple-600/40 to-transparent my-6"></div>
  </div>
);

const AdvancedSecurity = () => {
  return (
    <section id="security" className="mb-16">
      {/* Section header */}
      <div className="text-center mb-12">
        <div className="inline-block mb-4">
          <div className="flex items-center justify-center p-1.5 rounded-full bg-sortio-secondary/10 px-4 py-1.5">
            <Shield className="mr-2 text-sortio-secondary" size={14} />
            <span className="text-xs font-medium uppercase tracking-wider text-sortio-secondary">Security First</span>
          </div>
        </div>
        <h2 className="text-4xl font-semibold mb-5 tracking-tight text-apple-50">
          Advanced <span className="apple-gradient-text">Security</span>
        </h2>
        <p className="text-apple-300 text-lg mx-auto font-light max-w-2xl mb-10">
          Enterprise-grade security features to protect your organization's most sensitive data
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
        <SecurityFeature 
          icon={Shield}
          title="Enterprise-Grade Controls"
          description="Comprehensive security controls to protect your most sensitive data"
        />
        
        <SecurityFeature 
          icon={Lock}
          title="Data Encryption"
          description="End-to-end encryption options for data at rest and in transit"
        />
      </div>
      
      <div className="apple-panel backdrop-blur-xl p-8 rounded-3xl relative overflow-hidden group transition-all duration-500">
        <div className="inline-flex items-center justify-center w-12 h-12 rounded-2xl bg-sortio-accent bg-opacity-20 mb-6">
          <Building className="text-sortio-accent opacity-90" size={24} />
        </div>

        <h3 className="text-xl font-medium mb-3">On-Premises Deployment</h3>
        <p className="text-apple-300 mb-4">
          Keep your data within your organization's infrastructure with our on-premises deployment option. 
          Ideal for highly regulated industries and organizations with strict data residency requirements.
        </p>
        <div className="flex flex-wrap gap-3">
          <span className="px-3 py-1 bg-apple-600/50 rounded-full text-sm">Healthcare</span>
          <span className="px-3 py-1 bg-apple-600/50 rounded-full text-sm">Financial Services</span>
          <span className="px-3 py-1 bg-apple-600/50 rounded-full text-sm">Government</span>
          <span className="px-3 py-1 bg-apple-600/50 rounded-full text-sm">Legal</span>
        </div>
      </div>
    </section>
  );
};

export default AdvancedSecurity;