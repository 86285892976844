import React from 'react';
import AnimatedBackground from './AnimatedBackground';
import { Download, Check, Sparkles } from 'lucide-react';
import { Link } from 'react-router-dom';

const PricingSection = () => (
  <section id="pricing" className="py-16 sm:py-20 px-6 relative overflow-hidden" style={{ backgroundColor: "#121212", backgroundImage: "linear-gradient(45deg, rgba(255, 255, 255, 0.02) 25%, transparent 25%, transparent 75%, rgba(255, 255, 255, 0.02) 75%, rgba(255, 255, 255, 0.02)), linear-gradient(45deg, rgba(255, 255, 255, 0.02) 25%, transparent 25%, transparent 75%, rgba(255, 255, 255, 0.02) 75%, rgba(255, 255, 255, 0.02))", backgroundSize: "60px 60px" }}>
    {/* Section separator */}
    <div className="absolute top-0 inset-x-0 h-px bg-gradient-to-r from-transparent via-apple-600/40 to-transparent"></div>
    
    <div className="container mx-auto px-4 text-center max-w-6xl relative z-10">
      <div className="inline-block px-4 py-1.5 mb-4 rounded-full bg-apple-600/40 backdrop-blur-sm border border-apple-500/40">
        <span className="text-sm font-medium text-apple-100 flex items-center">
          <Sparkles className="w-4 h-4 mr-2 text-sortio-primary" />
          Limited Time Offer
        </span>
      </div>
      
      <h2 className="text-4xl lg:text-5xl font-bold mb-6">
        Simple, <span className="apple-gradient-text">Affordable</span> Pricing
      </h2>
      
      <p className="text-apple-300 mb-12 max-w-2xl mx-auto">
        One simple price. Lifetime access. No subscriptions.
      </p>
      
      <div className="max-w-md mx-auto">
        <div className="apple-panel p-8 rounded-apple relative">
          {/* Pricing highlight accent */}
          <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 px-6 py-1.5 rounded-full bg-sortio-primary/80 backdrop-blur-md border border-white/20 shadow-lg">
            <span className="text-sm font-medium text-white">Lifetime Deal</span>
          </div>
          
          <div className="pt-4">
            <div className="flex items-center justify-center mb-8">
              <span className="text-apple-300 text-xl mr-2">$</span>
              <span className="text-6xl font-bold text-apple-50">12</span>
              <div className="flex flex-col items-start justify-center ml-0.5">
                <span className="text-xl font-bold text-apple-50">.99</span>
                <span className="text-xs text-apple-300">one time</span>
              </div>
            </div>
            
            <ul className="list-none mb-8 space-y-4">
              {[
                "Unlimited sorting",
                "All features included",
                "Priority support",
                "One-time payment, lifetime access",
                "Regular updates"
              ].map((item, index) => (
                <li key={index} className="flex items-center">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3">
                    <Check className="w-3.5 h-3.5 text-sortio-primary" />
                  </div>
                  <span className="text-apple-100">{item}</span>
                </li>
              ))}
            </ul>
            
            <Link to="/download" className="block w-full">
              <button className="apple-button w-full py-4 flex items-center justify-center group">
                <span className="relative flex items-center">
                  <Download className="w-5 h-5 mr-2 group-hover:animate-pulse" />
                  <span className="font-medium">Download Now</span>
                </span>
              </button>
              <p className="mt-3 text-sm text-apple-400">
                Compatible with macOS 10.14+ and Windows 10/11
              </p>
            </Link>
          </div>
        </div>
        
        {/* Money back guarantee badge */}
        <div className="mt-8 inline-flex items-center px-4 py-2 rounded-full bg-apple-700/60 backdrop-blur-sm border border-apple-600/30">
          <svg className="w-5 h-5 mr-2 text-sortio-secondary" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <span className="text-sm font-medium text-apple-100">30-day money back guarantee</span>
        </div>
      </div>
    </div>
  </section>
);

export default PricingSection;