import React, { useState } from 'react';
import { Mail, Users, CheckCircle, User, Phone } from 'lucide-react';

const WaitlistSignup = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [companySize, setCompanySize] = useState('1-10');
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);
  
    const endpoint = 'https://app.getsortio.com/api/sortio-waitlist/';
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          name, 
          email, 
          phone_number: phone,
          company_size: companySize 
        }),
      });
  
      if (response.ok) {
        setSubmitStatus('success');
        setSubmitted(true);
        // Clear form fields after successful submission
        setName('');
        setEmail('');
        setPhone('');
        setCompanySize('1-10');
      } else {
        const errorData = await response.json();
        console.error('Submission error:', errorData);
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setSubmitStatus('error');
    }
  
    setIsSubmitting(false);
  };

  return (
    <section id="waitlist" className="mb-16">
      {/* Section header */}
      <div className="text-center mb-12">
        <div className="inline-block mb-4">
          <div className="flex items-center justify-center p-1.5 rounded-full bg-sortio-primary/10 px-4 py-1.5">
            <Mail className="mr-2 text-sortio-primary" size={14} />
            <span className="text-xs font-medium uppercase tracking-wider text-sortio-primary">Early Access</span>
          </div>
        </div>
        <h2 className="text-4xl font-semibold mb-5 tracking-tight text-apple-50">
          Join the <span className="apple-gradient-text">Waitlist</span>
        </h2>
        <p className="text-apple-300 text-lg mx-auto font-light max-w-2xl mb-10">
          Be among the first organizations to experience the power of AI-driven file organization
        </p>
      </div>
      
      <div className="bg-apple-700/30 backdrop-blur-md p-10 rounded-3xl border border-apple-600/30 relative overflow-hidden">
        {/* Gradient orbs in background */}
        <div className="absolute -left-40 -top-40 w-80 h-80 rounded-full bg-sortio-primary/10 opacity-30 blur-[100px]"></div>
        <div className="absolute -right-40 -bottom-40 w-80 h-80 rounded-full bg-sortio-secondary/10 opacity-30 blur-[100px]"></div>
        
        {!submitted ? (
          <form onSubmit={handleSubmit} className="max-w-lg mx-auto relative z-10">
            <p className="text-apple-300 mb-8 text-center">
              Sortio Business is currently in beta. Our team personally reviews each inquiry and will reach out to discuss 
              how Sortio can transform your organization's file management.
            </p>
            
            <div className="mb-6">
              <label htmlFor="name" className="block text-sm font-medium mb-2 text-apple-100">
                Full Name
              </label>
              <div className="relative">
                <User className="absolute left-4 top-3.5 text-apple-400" size={16} />
                <input
                  type="text"
                  id="name"
                  className="w-full px-4 py-3 pl-10 rounded-xl bg-apple-800/70 border border-apple-600/60 text-apple-50 focus:outline-none focus:ring-2 focus:ring-sortio-primary/50 focus:border-sortio-primary/50 transition-all"
                  placeholder="Your Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </div>
            
            <div className="mb-6">
              <label htmlFor="email" className="block text-sm font-medium mb-2 text-apple-100">
                Business Email
              </label>
              <div className="relative">
                <Mail className="absolute left-4 top-3.5 text-apple-400" size={16} />
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-3 pl-10 rounded-xl bg-apple-800/70 border border-apple-600/60 text-apple-50 focus:outline-none focus:ring-2 focus:ring-sortio-primary/50 focus:border-sortio-primary/50 transition-all"
                  placeholder="you@company.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            
            <div className="mb-6">
              <label htmlFor="phone" className="block text-sm font-medium mb-2 text-apple-100">
                Phone Number <span className="text-apple-400 text-xs">(Optional)</span>
              </label>
              <div className="relative">
                <Phone className="absolute left-4 top-3.5 text-apple-400" size={16} />
                <input
                  type="tel"
                  id="phone"
                  className="w-full px-4 py-3 pl-10 rounded-xl bg-apple-800/70 border border-apple-600/60 text-apple-50 focus:outline-none focus:ring-2 focus:ring-sortio-primary/50 focus:border-sortio-primary/50 transition-all"
                  placeholder="(555) 123-4567"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <p className="text-apple-400 text-xs mt-1">
                We'll only use this to schedule a personalized demo of Sortio Business
              </p>
            </div>
            
            <div className="mb-8">
              <label htmlFor="companySize" className="block text-sm font-medium mb-2 text-apple-100">
                Company Size
              </label>
              <div className="relative">
                <Users className="absolute left-4 top-3.5 text-apple-400" size={16} />
                <select
                  id="companySize"
                  className="w-full px-4 py-3 pl-10 rounded-xl bg-apple-800/70 border border-apple-600/60 text-apple-50 focus:outline-none focus:ring-2 focus:ring-sortio-primary/50 focus:border-sortio-primary/50 transition-all appearance-none"
                  value={companySize}
                  onChange={(e) => setCompanySize(e.target.value)}
                  required
                >
                  <option value="1-10">1-10 employees</option>
                  <option value="11-50">11-50 employees</option>
                  <option value="51-100">51-100 employees</option>
                  <option value="101-500">101-500 employees</option>
                  <option value="500+">500+ employees</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg className="h-5 w-5 text-apple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                  </svg>
                </div>
              </div>
            </div>
            
            <button 
              type="submit" 
              className="w-full bg-gradient-to-r from-sortio-primary to-sortio-primary/80 text-white font-medium px-8 py-4 text-base rounded-xl shadow-lg shadow-sortio-primary/20 hover:shadow-sortio-primary/30 hover:brightness-110 transition-all duration-300 flex items-center justify-center group"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </>
              ) : (
                <>
                  Request Early Access
                  <svg className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6"></path>
                  </svg>
                </>
              )}
            </button>
            
            {submitStatus === 'error' && (
              <div className="mt-4 p-3 bg-gradient-to-r from-red-500/80 to-red-600/60 text-white rounded-lg flex items-center text-sm">
                <svg className="w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <span>Oops! Something went wrong. Please try again later.</span>
              </div>
            )}
            
            <p className="text-apple-400 text-xs text-center mt-4">
              By submitting, you agree to be contacted about Sortio Business. We respect your privacy.
            </p>
          </form>
        ) : (
          <div className="text-center py-8 relative z-10 max-w-lg mx-auto">
            <div className="w-20 h-20 rounded-full bg-sortio-secondary/20 flex items-center justify-center mx-auto mb-6">
              <CheckCircle className="h-10 w-10 text-sortio-secondary" />
            </div>
            <h3 className="text-2xl font-medium mb-3 text-apple-50">Thank You!</h3>
            <p className="text-apple-300 mb-4">
              Your business inquiry has been received. A member of our team will be in touch shortly to 
              discuss how Sortio can transform your organization's file management.
            </p>
            <p className="text-apple-300 text-sm">
              If you provided a phone number, expect a call within 2 business days.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default WaitlistSignup;