import React from 'react';
import { Download, Apple, Monitor } from 'lucide-react';

const DownloadPage = () => {
  return (
    <div className="min-h-screen bg-black pt-24 pb-16 text-white">
      {/* Background blur orbs */}
      <div className="fixed -bottom-32 -right-32 w-96 h-96 bg-sortio-primary/10 rounded-full blur-3xl"></div>
      <div className="fixed -top-32 -left-32 w-96 h-96 bg-sortio-secondary/10 rounded-full blur-3xl"></div>
      
      <div className="max-w-5xl mx-auto px-6 relative z-10">
        {/* Hero section */}
        <div className="text-center mb-16">
          <h1 className="text-5xl md:text-6xl font-bold mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-apple-50 to-sortio-primary">Download Sortio</span>
          </h1>
          <p className="text-apple-300 text-lg md:text-xl max-w-2xl mx-auto leading-relaxed">
            Take control of your digital life. Download Sortio for your platform and experience effortless file organization.
          </p>
        </div>

        {/* Platform selection cards */}
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {/* macOS Download Card */}
          <div className="apple-panel group p-8 relative overflow-hidden">
            <div className="absolute -bottom-16 -right-16 w-32 h-32 bg-sortio-primary/10 rounded-full blur-2xl opacity-70 group-hover:opacity-100 transition-opacity duration-700"></div>
            
            <div className="flex items-center mb-6">
              <div className="p-3 bg-apple-800/50 rounded-2xl mr-4">
                <Apple size={32} className="text-apple-50" />
              </div>
              <h2 className="text-2xl font-semibold text-apple-50">macOS</h2>
            </div>
            
            <p className="text-apple-300 mb-6">Optimized for Apple Silicon and Intel processors. Seamless integration with macOS.</p>
            
            <div className="space-y-4">
              <a 
                href="https://apps.apple.com/us/app/sortio/id6737292062?mt=12"
                className="apple-button w-full py-3 flex justify-center group relative overflow-hidden"
              >
                <span className="z-10 relative flex items-center">
                  <Download className="w-5 h-5 mr-2" />
                  <span>Download from App Store</span>
                </span>
                <div className="absolute inset-0 bg-gradient-to-r from-sortio-primary/20 to-sortio-secondary/20 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-500"></div>
              </a>
              
              <div className="text-center text-apple-400 text-sm">
                macOS 11.0 or later
              </div>
            </div>
          </div>
          
          {/* Windows Download Card */}
          <div className="apple-panel group p-8 relative overflow-hidden">
            <div className="absolute -bottom-16 -right-16 w-32 h-32 bg-sortio-secondary/10 rounded-full blur-2xl opacity-70 group-hover:opacity-100 transition-opacity duration-700"></div>
            
            <div className="flex items-center mb-6">
              <div className="p-3 bg-apple-800/50 rounded-2xl mr-4">
                <Monitor size={32} className="text-apple-50" />
              </div>
              <h2 className="text-2xl font-semibold text-apple-50">Windows</h2>
            </div>
            
            <p className="text-apple-300 mb-6">For Windows 10 and 11. Seamlessly integrate Sortio into your Windows workflow.</p>
            
            <div className="space-y-4">
              <a 
                href="https://github.com/canonicalmg/Sortio-Releases/releases/download/Production/sortio_0.1.0_x64-setup.exe"
                className="apple-button w-full py-3 flex justify-center group relative overflow-hidden"
              >
                <span className="z-10 relative flex items-center">
                  <Download className="w-5 h-5 mr-2" />
                  <span>Download 64-bit</span>
                </span>
                <div className="absolute inset-0 bg-gradient-to-r from-sortio-primary/20 to-sortio-secondary/20 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-500"></div>
              </a>
              
              <a 
                href="https://github.com/canonicalmg/Sortio-Releases/releases/download/Production/sortio_0.1.0_x86-setup.exe"
                className="apple-panel-button w-full py-3 flex justify-center"
              >
                <Download className="w-5 h-5 mr-2" />
                <span>Download 32-bit</span>
              </a>
              
              <div className="text-center text-apple-400 text-sm">
                Windows 10 or later recommended
              </div>
            </div>
          </div>
        </div>
        
        {/* Installation Guide */}
        <div className="mt-20">
          <h2 className="text-3xl font-semibold mb-8 text-center">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-apple-100 to-apple-50">Installation Guide</span>
          </h2>
          
          <div className="apple-panel p-8">
            <div className="space-y-6">
              <div className="border-l-4 border-sortio-primary pl-4 py-2">
                <h3 className="text-xl font-semibold text-apple-50 mb-2">macOS Installation</h3>
                <ol className="list-decimal list-inside space-y-2 text-apple-300">
                  <li>Download Sortio from the Mac App Store.</li>
                  <li>Open the installed application from your Applications folder.</li>
                  <li>When prompted, allow Sortio to access the necessary permissions.</li>
                  <li>Follow the onboarding steps to set up your preferences.</li>
                </ol>
              </div>
              
              <div className="border-l-4 border-sortio-secondary pl-4 py-2">
                <h3 className="text-xl font-semibold text-apple-50 mb-2">Windows Installation</h3>
                <ol className="list-decimal list-inside space-y-2 text-apple-300">
                  <li>Download the appropriate installer for your system (64-bit or 32-bit).</li>
                  <li>Run the installer and follow the installation wizard.</li>
                  <li>Launch Sortio from the Start menu or desktop shortcut.</li>
                  <li>When prompted, allow Sortio to access the necessary permissions.</li>
                  <li>Follow the onboarding steps to set up your preferences.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;