import React from 'react';
import { Wand2, Brain, Settings, GitMerge } from 'lucide-react';

const RuleBasedSorting = () => {
  return (
    <section id="rule-based-sorting" className="mb-16">
      {/* Section header */}
      <div className="text-center mb-12">
        <div className="inline-block mb-4">
          <div className="flex items-center justify-center p-1.5 rounded-full bg-sortio-primary/10 px-4 py-1.5">
            <Wand2 className="mr-2 text-sortio-primary" size={14} />
            <span className="text-xs font-medium uppercase tracking-wider text-sortio-primary">Intelligent Rules</span>
          </div>
        </div>
        <h2 className="text-4xl font-semibold mb-5 tracking-tight text-apple-50">
          Rule-Based <span className="apple-gradient-text">Sorting System</span>
        </h2>
        <p className="text-apple-300 text-lg mx-auto font-light max-w-2xl mb-10">
          The perfect balance of AI-powered automation and customizable control for your organization
        </p>
      </div>
      
      <div className="apple-panel backdrop-blur-xl p-8 rounded-3xl mb-10 relative overflow-hidden">
        {/* Subtle gradient overlay */}
        <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-transparent via-sortio-primary/30 to-transparent"></div>
        
        <h3 className="text-xl font-medium mb-4 text-apple-50">Intelligent Organization</h3>
        <p className="text-apple-300 mb-8">
          Sortio Business combines AI-powered sorting with advanced rule configuration options, 
          giving you the perfect balance of automation and control.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-apple-700/40 p-6 rounded-2xl border border-apple-600/30 group transition-all hover:translate-y-[-5px] duration-300">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-2xl bg-sortio-primary/20 mb-4">
              <Brain className="text-sortio-primary" size={22} />
            </div>
            <h4 className="text-lg font-medium mb-3 text-apple-50">Default LLM-Powered Sorting</h4>
            <p className="text-apple-300 mb-4">
              Let AI handle the organization with smart, natural language sorting capabilities
            </p>
            <div className="bg-apple-800/70 p-4 rounded-lg font-mono text-sm border border-apple-700/50">
              <span className="text-sortio-primary">sort</span> <span className="text-sortio-secondary">--by-type</span> "resumes and marketing materials"
            </div>
          </div>
          
          <div className="bg-apple-700/40 p-6 rounded-2xl border border-apple-600/30 group transition-all hover:translate-y-[-5px] duration-300">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-2xl bg-sortio-secondary/20 mb-4">
              <Settings className="text-sortio-secondary" size={22} />
            </div>
            <h4 className="text-lg font-medium mb-3 text-apple-50">Advanced Rule Configuration</h4>
            <p className="text-apple-300 mb-4">
              Create precise sorting rules with our AI-First Modular Components
            </p>
            <div className="bg-apple-800/70 p-4 rounded-lg font-mono text-sm border border-apple-700/50">
              <span className="text-sortio-primary">rule</span> <span className="text-sortio-secondary">--create</span> "if &#123;is_resume&#125; then move to /Resumes/&#123;department&#125;"
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-apple-700/40 backdrop-blur-md p-8 rounded-3xl border border-apple-600/30 mb-10">
        <div className="flex items-center mb-5">
          <div className="inline-flex items-center justify-center w-10 h-10 rounded-xl bg-sortio-primary/20 mr-3">
            <GitMerge className="text-sortio-primary" size={20} />
          </div>
          <h3 className="text-xl font-medium text-apple-50">AI-First Modular Components</h3>
        </div>
        
        <p className="text-apple-300 mb-6">
          Create reusable rule components that can be shared across your organization
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-apple-800/50 p-5 rounded-xl border border-apple-700/50 hover:border-sortio-secondary/30 transition-colors">
            <h4 className="text-sm font-medium mb-2 text-sortio-secondary">LLM-Powered</h4>
            <p className="text-apple-300 text-sm">Components powered by AI with usage caps</p>
          </div>
          <div className="bg-apple-800/50 p-5 rounded-xl border border-apple-700/50 hover:border-sortio-secondary/30 transition-colors">
            <h4 className="text-sm font-medium mb-2 text-sortio-secondary">Generated Regex</h4>
            <p className="text-apple-300 text-sm">AI-generated regex for complex pattern matching</p>
          </div>
          <div className="bg-apple-800/50 p-5 rounded-xl border border-apple-700/50 hover:border-sortio-secondary/30 transition-colors">
            <h4 className="text-sm font-medium mb-2 text-sortio-secondary">Testable & Validatable</h4>
            <p className="text-apple-300 text-sm">Test components before deployment</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RuleBasedSorting;