import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import DemoSection from './components/DemoSection';
import FeaturesSection from './components/FeaturesSection';
import SecuritySection from './components/SecuritySection';
import PricingSection from './components/PricingSection';
import TestimonialsSection from './components/TestimonialsSection';
import FAQsSection from './components/FAQsSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import DownloadPage from './pages/DownloadPage';
import ArticlesPage from './pages/ArticlesPage';
import ArticlePage from './pages/ArticlePage';
import CategoryPage from './pages/CategoryPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import AboutUsPage from './pages/AboutUsPage';
import BusinessPage from './pages/BusinessPage';
import { Analytics } from '@vercel/analytics/react';

// Layout wrapper that conditionally includes the Header based on route
const MainLayout = ({ children }) => {
  const location = useLocation();
  const isBusinessPage = location.pathname === '/business';
  
  return (
    <div className="min-h-screen text-apple-50">
      {!isBusinessPage && <Header />}
      {children}
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <MainLayout>
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <div className="max-w-[1400px] mx-auto" style={{ background: 'transparent' }}>
                <DemoSection />
                <TestimonialsSection />
                <SecuritySection />
                <FeaturesSection />
                <PricingSection />
                <FAQsSection />
                <ContactSection />
              </div>
            </>
          } />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/articles" element={<ArticlesPage />} />
          <Route path="/articles/:category/:articleSlug" element={<ArticlePage />} />
          <Route path="/articles/:categorySlug" element={<CategoryPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/business" element={<BusinessPage />} />
        </Routes>
      </MainLayout>
      <Analytics />
    </Router>
  );
};

export default App;