import React, { useState } from 'react';
import { Send, Lightbulb, Mail, MessageSquare, Sparkles } from 'lucide-react';
import AnimatedBackground from './AnimatedBackground';

const ContactSection = () => {
  const [formType, setFormType] = useState('contact');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [focusedField, setFocusedField] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);
  
    const endpoint = 'https://app.getsortio.com/api/contact-or-suggest/';
  
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          type: formType,
          name: name, 
          email: email, 
          message: message 
        }),
      });
  
      if (response.ok) {
        setSubmitStatus('success');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        const errorData = await response.json();
        console.error('Submission error:', errorData);
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setSubmitStatus('error');
    }
  
    setIsSubmitting(false);
  };

  return (
    <section id="contact" className="py-16 sm:py-20 px-6 relative overflow-hidden" style={{ background: "#000000" }}>
      
      <div className="container mx-auto px-4 max-w-4xl relative z-10">
        <div className="flex flex-col items-center mb-12">
          <div className="mb-4 flex justify-center">
            <span className="inline-block px-3 py-1.5 rounded-full bg-sortio-primary/10 text-sortio-primary text-sm font-medium">
              Get in Touch
            </span>
          </div>
          <h2 className="text-4xl lg:text-5xl font-bold text-center">
            We'd love to <span className="apple-gradient-text">hear from you</span>
          </h2>
          <p className="mt-4 text-apple-300 max-w-lg text-center">
            Questions, suggestions, or just want to say hello? We're here to help.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Left side info cards */}
          <div className="space-y-4">
            <div className="apple-panel p-5 rounded-apple">
              <div className="flex items-start">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-4">
                  <Mail className="w-5 h-5 text-sortio-primary" />
                </div>
                <div>
                  <h3 className="text-base font-medium text-apple-50">Contact Us</h3>
                  <p className="mt-1 text-sm text-apple-300">Use the form for quick replies</p>
                </div>
              </div>
            </div>
            
            <div className="apple-panel p-5 rounded-apple">
              <div className="flex items-start">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-sortio-secondary/20 flex items-center justify-center mr-4">
                  <MessageSquare className="w-5 h-5 text-sortio-secondary" />
                </div>
                <div>
                  <h3 className="text-base font-medium text-apple-50">Response Time</h3>
                  <p className="mt-1 text-sm text-apple-300">We typically respond within 24 hours</p>
                </div>
              </div>
            </div>
            
            <div className="apple-panel p-5 rounded-apple hidden md:block">
              <div className="flex items-start">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-sortio-accent/20 flex items-center justify-center mr-4">
                  <Sparkles className="w-5 h-5 text-sortio-accent" />
                </div>
                <div>
                  <h3 className="text-base font-medium text-apple-50">Feature Request</h3>
                  <p className="mt-1 text-sm text-apple-300">We love hearing your ideas for Sortio</p>
                </div>
              </div>
            </div>
          </div>
          
          {/* Right side form */}
          <div className="md:col-span-2">
            <div className="apple-panel p-6 rounded-apple">
              <div className="mb-6 flex space-x-4">
                <button
                  className={`relative px-4 py-2.5 rounded-full border border-apple-600/40 transition-all duration-300 
                    ${formType === 'contact' ? 'bg-sortio-primary text-white' : 'bg-apple-700/80 text-apple-200 hover:bg-apple-600/40'}`}
                  onClick={() => setFormType('contact')}
                >
                  <div className="flex items-center">
                    <Send className="w-4 h-4 mr-2" />
                    <span className="text-sm font-medium">Contact Us</span>
                  </div>
                  {formType === 'contact' && (
                    <span className="absolute -bottom-px left-1/2 w-12 h-px bg-white transform -translate-x-1/2"></span>
                  )}
                </button>
                <button
                  className={`relative px-4 py-2.5 rounded-full border border-apple-600/40 transition-all duration-300 
                    ${formType === 'suggestion' ? 'bg-sortio-primary text-white' : 'bg-apple-700/80 text-apple-200 hover:bg-apple-600/40'}`}
                  onClick={() => setFormType('suggestion')}
                >
                  <div className="flex items-center">
                    <Lightbulb className="w-4 h-4 mr-2" />
                    <span className="text-sm font-medium">Suggest Feature</span>
                  </div>
                  {formType === 'suggestion' && (
                    <span className="absolute -bottom-px left-1/2 w-12 h-px bg-white transform -translate-x-1/2"></span>
                  )}
                </button>
              </div>
              
              <form onSubmit={handleSubmit} className="space-y-5">
                <div className="space-y-1.5">
                  <label htmlFor="name" className="text-xs font-medium text-apple-300">Name</label>
                  <div className={`relative transition-all duration-300 ${focusedField === 'name' ? 'ring-1 ring-sortio-primary' : ''}`}>
                    <input
                      id="name"
                      type="text"
                      placeholder="Your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onFocus={() => setFocusedField('name')}
                      onBlur={() => setFocusedField(null)}
                      className="w-full p-3 rounded-lg bg-apple-700/40 border border-apple-600/40 text-apple-100 
                               placeholder:text-apple-400 focus:outline-none"
                      required
                    />
                  </div>
                </div>
                
                <div className="space-y-1.5">
                  <label htmlFor="email" className="text-xs font-medium text-apple-300">Email</label>
                  <div className={`relative transition-all duration-300 ${focusedField === 'email' ? 'ring-1 ring-sortio-primary' : ''}`}>
                    <input
                      id="email"
                      type="email"
                      placeholder="your.email@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={() => setFocusedField('email')}
                      onBlur={() => setFocusedField(null)}
                      className="w-full p-3 rounded-lg bg-apple-700/40 border border-apple-600/40 text-apple-100 
                               placeholder:text-apple-400 focus:outline-none"
                      required
                    />
                  </div>
                </div>
                
                <div className="space-y-1.5">
                  <label htmlFor="message" className="text-xs font-medium text-apple-300">
                    {formType === 'contact' ? 'Message' : 'Feature Suggestion'}
                  </label>
                  <div className={`relative transition-all duration-300 ${focusedField === 'message' ? 'ring-1 ring-sortio-primary' : ''}`}>
                    <textarea
                      id="message"
                      placeholder={formType === 'contact' ? "How can we help you?" : "Describe your feature idea..."}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onFocus={() => setFocusedField('message')}
                      onBlur={() => setFocusedField(null)}
                      rows="4"
                      className="w-full p-3 rounded-lg bg-apple-700/40 border border-apple-600/40 text-apple-100 
                               placeholder:text-apple-400 focus:outline-none custom-scrollbar"
                      required
                    ></textarea>
                  </div>
                </div>
                
                <button 
                  type="submit"
                  className="w-full apple-button py-3.5 group relative overflow-hidden"
                  disabled={isSubmitting}
                >
                  <span className="absolute inset-0 w-full h-full bg-gradient-to-r from-sortio-primary/0 via-white/20 to-sortio-primary/0 
                                 -translate-x-full group-hover:animate-[shimmer_1.5s_infinite] opacity-0 group-hover:opacity-100"></span>
                  <span className="relative flex items-center justify-center">
                    {isSubmitting ? (
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    ) : formType === 'contact' ? (
                      <Send className="w-4 h-4 mr-2" />
                    ) : (
                      <Lightbulb className="w-4 h-4 mr-2" />
                    )}
                    {isSubmitting ? 'Sending...' : formType === 'contact' ? 'Send Message' : 'Submit Suggestion'}
                  </span>
                </button>
              </form>
              
              {submitStatus === 'success' && (
                <div className="mt-4 p-3 bg-gradient-to-r from-sortio-secondary/80 to-sortio-secondary/60 text-white rounded-lg flex items-center">
                  <svg className="w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  <span>Thank you! We've received your {formType === 'contact' ? 'message' : 'suggestion'} and will get back to you shortly.</span>
                </div>
              )}
              
              {submitStatus === 'error' && (
                <div className="mt-4 p-3 bg-gradient-to-r from-sortio-accent/80 to-sortio-accent/60 text-white rounded-lg flex items-center">
                  <svg className="w-5 h-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                  <span>Oops! Something went wrong. Please try again later.</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;