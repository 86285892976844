import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';
import { ChevronRight, ArrowRight, Calendar, Clock, User, Hash, ChevronDown, ExternalLink, ArrowLeft } from 'lucide-react';
import config from '../config';
import Breadcrumbs from '../components/Breadcrumbs';
import ArticleEnhancements from '../components/ArticleEnhancements';
import ArticleStructuredData from '../components/ArticleStructuredData';
import TableOfContents from '../components/TableOfContents';
import parse from 'html-react-parser';

const ArticlePage = () => {
  const { category, articleSlug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tocVisible, setTocVisible] = useState(false);

  useEffect(() => {
    fetchArticle();
  }, [category, articleSlug]);

  const fetchArticle = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${config.apiUrl}/articles/${category}/${articleSlug}/`);
      if (!response.ok) {
        throw new Error('Article not found');
      }
      const data = await response.json();
      setArticle(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching article:', error);
      setError('Failed to load the article. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const toggleToc = () => {
    setTocVisible(!tocVisible);
  };

  // Generate a placeholder article if none exists
  const placeholderArticle = {
    title: 'How AI is Revolutionizing File Organization',
    content: '<h2 id="introduction">Introduction</h2><p>Artificial intelligence is transforming how we interact with digital files. By analyzing content, metadata, and usage patterns, AI can automate organization tasks that once required manual effort.</p><h2 id="benefits">Key Benefits of AI File Organization</h2><p>The adoption of AI for file organization offers numerous advantages:</p><ul><li>Automated sorting based on content and context</li><li>Intelligent tagging and metadata extraction</li><li>Personalized organization schemes that adapt to your workflow</li><li>Reduced time spent on manual file management</li><li>Improved searchability and discoverability</li></ul><h2 id="technologies">Core Technologies</h2><p>Several technologies power modern AI file organizers:</p><p><strong>Natural Language Processing (NLP)</strong> enables the system to understand document content, extract key topics, and categorize accordingly.</p><p><strong>Computer Vision</strong> helps analyze images and videos, automatically tagging visual content based on what appears in the file.</p><p><strong>Machine Learning</strong> allows the system to improve organization over time by learning from user behavior and preferences.</p>',
    created_at: '2023-09-15T10:00:00Z',
    table_of_contents: [
      { id: 'introduction', title: 'Introduction' },
      { id: 'benefits', title: 'Key Benefits of AI File Organization' },
      { id: 'technologies', title: 'Core Technologies' },
    ],
    read_time: '5 min',
    author: 'AI Research Team',
    excerpt: 'Discover how artificial intelligence is transforming the way we organize and manage digital files, making it easier than ever to maintain a clean and structured digital workspace.',
    tags: ['AI', 'Organization', 'Productivity', 'File Management']
  };

  const displayArticle = article || placeholderArticle;

  // Function to split content into sections based on <h2> tags
  const splitContentIntoSections = (htmlContent) => {
    const sanitizedContent = DOMPurify.sanitize(htmlContent);
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedContent, 'text/html');
    const body = doc.body;

    const sections = [];
    let currentSection = { title: null, content: [] };

    Array.from(body.children).forEach((node) => {
      if (node.tagName.toLowerCase() === 'h2') {
        // Push the previous section if it exists
        if (currentSection.title) {
          sections.push({ ...currentSection });
          currentSection = { title: null, content: [] };
        }
        currentSection.title = node.textContent;
      } else {
        if (!currentSection.title) {
          // If there's content before the first <h2>, assign it to a default section
          currentSection.title = 'Introduction';
        }
        currentSection.content.push(node.outerHTML);
      }
    });

    // Push the last section
    if (currentSection.title) {
      sections.push({ ...currentSection });
    }

    return sections;
  };

  const sections = splitContentIntoSections(displayArticle.content);

  if (loading) {
    return (
      <div className="min-h-screen bg-black pt-24 pb-16">
        <div className="container mx-auto px-6">
          <div className="flex justify-center py-16">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-sortio-primary"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-black pt-24 pb-16">
        <div className="container mx-auto px-6">
          <div className="apple-panel p-8 text-center border-red-500/20">
            <p className="text-red-400">{error}</p>
            <Link to="/articles" className="mt-4 inline-flex items-center text-apple-300 hover:text-sortio-primary">
              <ArrowLeft className="mr-2 w-4 h-4" />
              Back to Articles
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black pt-24 pb-16">
      {/* Background blur orbs */}
      <div className="fixed -bottom-32 -right-32 w-96 h-96 bg-sortio-primary/10 rounded-full blur-3xl opacity-60"></div>
      <div className="fixed top-1/2 -left-32 w-96 h-96 bg-sortio-secondary/10 rounded-full blur-3xl opacity-60"></div>

      <Helmet>
        <title>{`${displayArticle.title} - Sortio`}</title>
        <meta name="description" content={displayArticle.excerpt} />
        <link rel="canonical" href={`https://www.getsortio.com/articles/${category}/${articleSlug}`} />
        <meta property="og:title" content={displayArticle.title} />
        <meta property="og:description" content={displayArticle.excerpt} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.getsortio.com/articles/${category}/${articleSlug}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={displayArticle.title} />
        <meta name="twitter:description" content={displayArticle.excerpt} />
      </Helmet>

      <div className="container mx-auto px-6 relative z-10">
        <Breadcrumbs category={category} articleTitle={displayArticle.title} />

        <div className="flex flex-col lg:flex-row lg:gap-8 mt-8">
          {/* Main content */}
          <article className="lg:w-2/3">
            {/* Article header */}
            <div className="apple-panel p-8 mb-8">
              <h1 className="text-3xl md:text-4xl font-bold mb-4 text-apple-50">
                {displayArticle.title}
              </h1>
              
              <div className="flex flex-wrap gap-4 text-sm text-apple-400 mb-4">
                <div className="flex items-center">
                  <Calendar className="w-4 h-4 mr-1.5" />
                  <span>{formatDate(displayArticle.created_at)}</span>
                </div>
                
                {displayArticle.read_time && (
                  <div className="flex items-center">
                    <Clock className="w-4 h-4 mr-1.5" />
                    <span>{displayArticle.read_time}</span>
                  </div>
                )}
                
                {displayArticle.author && (
                  <div className="flex items-center">
                    <User className="w-4 h-4 mr-1.5" />
                    <span>{displayArticle.author}</span>
                  </div>
                )}
              </div>
              
              <p className="text-apple-300 text-lg leading-relaxed">{displayArticle.excerpt}</p>
              
              {/* Mobile TOC toggle */}
              <button 
                onClick={toggleToc} 
                className="lg:hidden mt-6 w-full flex items-center justify-between p-3 bg-apple-800/50 rounded-lg text-apple-200 hover:bg-apple-700/70 transition-colors"
              >
                <span className="font-medium">Table of Contents</span>
                <ChevronDown className={`w-5 h-5 transition-transform duration-300 ${tocVisible ? 'transform rotate-180' : ''}`} />
              </button>
              
              {tocVisible && (
                <div className="lg:hidden mt-3 p-4 bg-apple-800/30 rounded-lg">
                  <TableOfContents tableOfContents={displayArticle.table_of_contents} />
                </div>
              )}
            </div>
            
            {/* Article content */}
            <div className="space-y-8">
              {displayArticle.youtube_video_url && (
                <div className="mb-8">
                  <iframe
                    width="100%"
                    height="315"
                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(displayArticle.youtube_video_url)}?rel=0`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="rounded-lg shadow-lg"
                  ></iframe>
                </div>
              )}

              <ArticleEnhancements article={displayArticle} content={displayArticle.content} />
              
              {sections.map((section, index) => {
                const sectionId = displayArticle.table_of_contents[index]?.id || `section-${index}`;
                return (
                  <section key={index} id={sectionId} className="apple-panel p-8">
                    <h2 className="text-2xl font-bold mb-6 text-apple-50 border-b border-apple-700/50 pb-3">
                      {section.title}
                    </h2>
                    <div className="prose prose-invert max-w-none prose-headings:text-apple-100 prose-p:text-apple-300 prose-strong:text-apple-200 prose-li:text-apple-300 prose-a:text-sortio-primary hover:prose-a:text-sortio-primary/80 prose-a:no-underline hover:prose-a:underline">
                      {parse(section.content.join(' '))}
                    </div>
                  </section>
                );
              })}
            </div>
            
            {/* Tags */}
            {displayArticle.tags && displayArticle.tags.length > 0 && (
              <div className="mt-8 apple-panel p-6">
                <div className="flex flex-wrap items-center gap-2">
                  <span className="text-apple-400 flex items-center">
                    <Hash className="w-4 h-4 mr-1" />
                    Tags:
                  </span>
                  {displayArticle.tags.map((tag, index) => (
                    <Link 
                      key={index} 
                      to={`/articles?tag=${tag}`}
                      className="px-3 py-1 bg-apple-800/50 hover:bg-apple-700/70 rounded-full text-sm text-apple-300 hover:text-sortio-primary transition-colors"
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              </div>
            )}
            
            {/* Navigation buttons */}
            <div className="mt-8 flex justify-between">
              <Link 
                to="/articles"
                className="apple-panel-button inline-flex items-center px-5 py-2.5"
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                All Articles
              </Link>
              
              <a 
                href="#top"
                className="apple-panel-button inline-flex items-center px-5 py-2.5"
              >
                Back to Top
                <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 19V5M5 12L12 5L19 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            </div>
          </article>
          
          {/* Sidebar */}
          <aside className="lg:w-1/3 mt-8 lg:mt-0">
            {/* Desktop TOC */}
            <div className="hidden lg:block sticky top-24 apple-panel p-6 mb-8">
              <h3 className="text-lg font-medium mb-4 text-apple-100 flex items-center">
                <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7" />
                </svg>
                Table of Contents
              </h3>
              <TableOfContents tableOfContents={displayArticle.table_of_contents} />
            </div>
            
            {/* Related content */}
            <div className="apple-panel p-6">
              <h3 className="text-lg font-medium mb-4 text-apple-100">Get Sortio</h3>
              <p className="text-apple-300 mb-4">Ready to experience the power of AI-driven file organization?</p>
              <Link to="/download" className="apple-button w-full justify-center py-2.5 mb-4 flex">
                Download Now
              </Link>
              <a href="/#pricing" className="apple-panel-button w-full justify-center py-2.5 flex">
                View Pricing
              </a>
            </div>
          </aside>
        </div>
      </div>
      <ArticleStructuredData article={displayArticle} category={category} />
    </div>
  );
};

// Helper function to extract YouTube video ID from URL
const getYouTubeVideoId = (url) => {
  if (!url) return null;
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

export default ArticlePage;