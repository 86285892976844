import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Shield, AlertCircle, CheckCircle, ChevronLeft } from 'lucide-react';

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen bg-black text-apple-100 py-24">
      <Helmet>
        <title>Privacy Policy - Sortio</title>
        <meta name="description" content="Privacy Policy for Sortio, the AI-powered file organization app." />
      </Helmet>
      
      {/* Background gradient */}
      <div className="absolute top-0 inset-x-0 h-[500px] bg-gradient-to-b from-apple-700/10 to-transparent"></div>
      <div className="absolute -top-[600px] -right-[300px] w-[1000px] h-[1000px] bg-sortio-primary/5 rounded-full blur-3xl"></div>
      
      <div className="container mx-auto px-6 max-w-4xl relative z-10">
        {/* Back button */}
        <Link to="/" className="inline-flex items-center text-apple-300 hover:text-sortio-primary mb-12 transition-colors group">
          <ChevronLeft className="w-4 h-4 mr-1 group-hover:-translate-x-1 transition-transform" />
          <span>Back to Home</span>
        </Link>
        
        {/* Header */}
        <div className="mb-16 flex flex-col items-center">
          <div className="w-14 h-14 rounded-full bg-apple-700/60 backdrop-blur-sm flex items-center justify-center mb-6 border border-apple-600/30">
            <Shield className="w-6 h-6 text-sortio-primary" />
          </div>
          <h1 className="text-4xl lg:text-5xl font-bold text-center">
            Privacy <span className="apple-gradient-text">Policy</span>
          </h1>
          <p className="text-apple-300 mt-4 text-center max-w-xl">
            We're committed to protecting your privacy and ensuring your data remains secure.
          </p>
        </div>
        
        {/* Content */}
        <div className="apple-panel p-8 md:p-12 rounded-apple backdrop-blur-sm mb-12">
          <div className="space-y-12">
            {/* Agreement Notice */}
            <div className="p-4 rounded-lg bg-sortio-primary/10 border border-sortio-primary/30">
              <p className="text-apple-200 text-sm font-medium">
                BY DOWNLOADING, INSTALLING, OR USING THE SORTIO APPLICATION, YOU CONSENT TO THE DATA PRACTICES DESCRIBED IN THIS PRIVACY POLICY. IF YOU DO NOT AGREE WITH THE DATA PRACTICES DESCRIBED IN THIS PRIVACY POLICY, YOU SHOULD NOT USE THE APPLICATION.
              </p>
            </div>
            
            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">1</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Introduction</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                At Sortio, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our application. By accessing or using Sortio, you acknowledge that you have read, understood, and agree to be bound by all terms and conditions of this Privacy Policy and our Terms and Conditions.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-secondary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-secondary font-medium text-sm">2</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Information We Don't Collect</h2>
              </div>
              <div className="pl-11">
                <p className="text-apple-300 leading-relaxed mb-3">
                  We want to be clear about what we don't do:
                </p>
                <ul className="space-y-2">
                  {[
                    "We do not collect or store the contents of your files.",
                    "We do not retain any personal information beyond what's necessary for the functioning of the app.",
                    "We do not use your data to train our AI models."
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <AlertCircle className="w-5 h-5 text-sortio-accent mr-3 mt-0.5 flex-shrink-0" />
                      <span className="text-apple-300">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">3</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Information We Do Collect</h2>
              </div>
              <div className="pl-11">
                <p className="text-apple-300 leading-relaxed mb-3">
                  The only information we process is:
                </p>
                <ul className="space-y-2">
                  {[
                    "Filenames (not file contents) for the purpose of organizing your files.",
                    "Basic usage data to improve our service (e.g., app performance, error logs)."
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <CheckCircle className="w-5 h-5 text-sortio-secondary mr-3 mt-0.5 flex-shrink-0" />
                      <span className="text-apple-300">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">4</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">How We Use Your Information</h2>
              </div>
              <div className="pl-11">
                <p className="text-apple-300 leading-relaxed mb-3">
                  We use the collected information solely for:
                </p>
                <ul className="space-y-2">
                  {[
                    "Providing and maintaining our file organization service.",
                    "Improving and optimizing our application.",
                    "Responding to your inquiries and customer service requests."
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <CheckCircle className="w-5 h-5 text-sortio-secondary mr-3 mt-0.5 flex-shrink-0" />
                      <span className="text-apple-300">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">5</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Data Security</h2>
              </div>
              <div className="pl-11">
                <p className="text-apple-300 leading-relaxed mb-3">
                  We implement robust security measures to protect your information:
                </p>
                <ul className="space-y-2">
                  {[
                    "All data processed through our app is encrypted.",
                    "We use industry-standard protocols to safeguard your information."
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <Shield className="w-5 h-5 text-sortio-secondary mr-3 mt-0.5 flex-shrink-0" />
                      <span className="text-apple-300">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">6</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Your Rights</h2>
              </div>
              <div className="pl-11">
                <p className="text-apple-300 leading-relaxed mb-3">
                  You have the right to:
                </p>
                <ul className="space-y-2">
                  {[
                    "Access the personal information we hold about you.",
                    "Request that your personal information be corrected or deleted.",
                    "Object to the processing of your personal information."
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <CheckCircle className="w-5 h-5 text-sortio-secondary mr-3 mt-0.5 flex-shrink-0" />
                      <span className="text-apple-300">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">7</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Children's Privacy</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                Our Application is not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and believe your child has provided us with personal information, please contact us immediately. If we discover that a child under 13 has provided us with personal information, we will promptly delete such information from our servers.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">8</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Third-Party Services</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                Our Application may contain links to third-party websites, services, or tools that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such website or service.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">9</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">International Transfers</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                Your information, including any personal data that we collect, may be transferred to, stored at, and processed by us and our service providers outside of the country in which you reside, where data protection and privacy regulations may not offer the same level of protection as in your jurisdiction. By using our Application, you consent to this transfer, storing, or processing.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">10</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Changes to This Privacy Policy</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                We may update our Privacy Policy from time to time at our sole discretion. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. Your continued use of the Application after we post any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">11</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Contact Us</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                If you have any questions about this Privacy Policy, please{' '}
                <Link to="/#contact" className="text-sortio-primary hover:text-sortio-primary/80 transition-colors">
                  contact us
                </Link>.
              </p>
            </section>
          </div>
          
          {/* Last updated */}
          <div className="mt-12 pt-6 border-t border-apple-700/20">
            <p className="text-sm text-apple-400">Last Updated: October 12, 2024</p>
          </div>
        </div>
        
        {/* Back to top button */}
        <div className="flex justify-center mb-8">
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="inline-flex items-center px-4 py-2 rounded-full bg-apple-700/40 border border-apple-600/30 text-sm text-apple-300 hover:text-sortio-primary transition-colors"
          >
            <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="m18 15-6-6-6 6"/>
            </svg>
            <span>Back to top</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;