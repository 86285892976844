import React from 'react';
import { BarChart3, Check, X, Clock, ArrowRight } from 'lucide-react';

const CompetitivePositioning = () => {
  return (
    <section className="mb-16">
      {/* Section header */}
      <div className="text-center mb-12">
        <div className="inline-block mb-4">
          <div className="flex items-center justify-center p-1.5 rounded-full bg-sortio-secondary/10 px-4 py-1.5">
            <BarChart3 className="mr-2 text-sortio-secondary" size={14} />
            <span className="text-xs font-medium uppercase tracking-wider text-sortio-secondary">Compare Solutions</span>
          </div>
        </div>
        <h2 className="text-4xl font-semibold mb-5 tracking-tight text-apple-50">
          How We <span className="apple-gradient-text">Compare</span>
        </h2>
        <p className="text-apple-300 text-lg mx-auto font-light max-w-2xl mb-10">
          See how Sortio Business stands out against traditional document management solutions
        </p>
      </div>
      
      <div className="bg-apple-700/30 backdrop-blur-md rounded-3xl border border-apple-600/30 overflow-hidden relative">
        {/* Gradient background */}
        <div className="absolute inset-0 bg-gradient-to-br from-sortio-primary/5 to-sortio-secondary/5 opacity-20"></div>
        
        {/* Product highlight banner */}
        <div className="bg-sortio-primary/10 border-b border-sortio-primary/20 p-3 text-center">
          <span className="text-sortio-primary font-medium">Sortio Business outperforms traditional document management systems in every category</span>
        </div>
        
        <div className="overflow-x-auto p-2 relative z-10">
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b border-apple-600/50">
                <th className="py-5 px-6 text-left text-apple-50 w-1/4">Feature</th>
                <th className="py-5 px-6 text-center w-1/5">
                  <div className="flex flex-col items-center">
                    <div className="bg-sortio-primary/10 rounded-full p-2 mb-2">
                      <img src="/logo.png" alt="Sortio" className="h-8 w-8" />
                    </div>
                    <span className="text-sortio-primary font-medium">Sortio Business</span>
                  </div>
                </th>
                <th className="py-5 px-6 text-center w-1/5">
                  <div className="flex flex-col items-center opacity-60">
                    <div className="bg-apple-600/20 rounded-full p-2 mb-2">
                      <svg className="h-8 w-8 text-apple-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                      </svg>
                    </div>
                    <span className="text-apple-300">Cloud Storage</span>
                  </div>
                </th>
                <th className="py-5 px-6 text-center w-1/5">
                  <div className="flex flex-col items-center opacity-60">
                    <div className="bg-apple-600/20 rounded-full p-2 mb-2">
                      <svg className="h-8 w-8 text-apple-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                      </svg>
                    </div>
                    <span className="text-apple-300">Traditional DMS</span>
                  </div>
                </th>
                <th className="py-5 px-6 text-center w-1/5">
                  <div className="flex flex-col items-center opacity-60">
                    <div className="bg-apple-600/20 rounded-full p-2 mb-2">
                      <svg className="h-8 w-8 text-apple-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                      </svg>
                    </div>
                    <span className="text-apple-300">Search Tools</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-apple-600/30 hover:bg-apple-800/20 transition-colors">
                <td className="py-5 px-6">
                  <div>
                    <div className="font-medium text-apple-50 mb-1">Works with existing files</div>
                    <div className="text-sm text-apple-300">No migration required</div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-sortio-secondary/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Check className="h-5 w-5 text-sortio-secondary" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-red-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <X className="h-5 w-5 text-red-500" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-red-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <X className="h-5 w-5 text-red-500" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-sortio-secondary/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Check className="h-5 w-5 text-sortio-secondary" />
                    </div>
                  </div>
                </td>
              </tr>
              
              <tr className="border-b border-apple-600/30 hover:bg-apple-800/20 transition-colors">
                <td className="py-5 px-6">
                  <div>
                    <div className="font-medium text-apple-50 mb-1">AI-powered organization</div>
                    <div className="text-sm text-apple-300">Smart sorting & categorization</div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-sortio-secondary/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Check className="h-5 w-5 text-sortio-secondary" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-red-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <X className="h-5 w-5 text-red-500" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-yellow-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Clock className="h-5 w-5 text-yellow-500" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-red-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <X className="h-5 w-5 text-red-500" />
                    </div>
                  </div>
                </td>
              </tr>
              
              <tr className="border-b border-apple-600/30 hover:bg-apple-800/20 transition-colors">
                <td className="py-5 px-6">
                  <div>
                    <div className="font-medium text-apple-50 mb-1">Natural language interface</div>
                    <div className="text-sm text-apple-300">Find and organize with plain language</div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-sortio-secondary/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Check className="h-5 w-5 text-sortio-secondary" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-yellow-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Clock className="h-5 w-5 text-yellow-500" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-red-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <X className="h-5 w-5 text-red-500" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-yellow-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Clock className="h-5 w-5 text-yellow-500" />
                    </div>
                  </div>
                </td>
              </tr>
              
              <tr className="border-b border-apple-600/30 hover:bg-apple-800/20 transition-colors">
                <td className="py-5 px-6">
                  <div>
                    <div className="font-medium text-apple-50 mb-1">Implementation cost</div>
                    <div className="text-sm text-apple-300">Time and resources required</div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <span className="bg-green-500/10 text-green-400 px-3 py-1 rounded-full font-medium text-sm">Low</span>
                </td>
                <td className="py-5 px-6 text-center">
                  <span className="bg-yellow-500/10 text-yellow-400 px-3 py-1 rounded-full font-medium text-sm">Medium</span>
                </td>
                <td className="py-5 px-6 text-center">
                  <span className="bg-red-500/10 text-red-400 px-3 py-1 rounded-full font-medium text-sm">High</span>
                </td>
                <td className="py-5 px-6 text-center">
                  <span className="bg-yellow-500/10 text-yellow-400 px-3 py-1 rounded-full font-medium text-sm">Medium</span>
                </td>
              </tr>
              
              <tr className="hover:bg-apple-800/20 transition-colors">
                <td className="py-5 px-6">
                  <div>
                    <div className="font-medium text-apple-50 mb-1">Actionable insights</div>
                    <div className="text-sm text-apple-300">Data-driven decision making</div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-sortio-secondary/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Check className="h-5 w-5 text-sortio-secondary" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-red-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <X className="h-5 w-5 text-red-500" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-yellow-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Clock className="h-5 w-5 text-yellow-500" />
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-center">
                  <div className="flex justify-center">
                    <div className="bg-yellow-500/10 w-10 h-10 rounded-full flex items-center justify-center">
                      <Clock className="h-5 w-5 text-yellow-500" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        {/* Call to Action */}
        <div className="border-t border-apple-600/30 p-4 flex justify-between items-center bg-apple-800/20">
          <div className="text-apple-200 text-sm">See why more businesses choose Sortio for document organization</div>
          <a href="#waitlist" className="flex items-center text-sortio-primary hover:text-sortio-secondary transition-colors text-sm font-medium">
            <span>Join the waitlist</span>
            <ArrowRight className="ml-1 h-4 w-4" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default CompetitivePositioning;