import React from 'react';
import { Cloud, Code, Zap, Sparkles } from 'lucide-react';

const IntegrationCard = ({ icon: Icon, title, description, color = "from-sortio-secondary to-sortio-secondary/70" }) => (
  <div className="bg-apple-700/40 backdrop-blur-md p-8 rounded-3xl border border-apple-600/30 transition-all duration-500 
                  hover:translate-y-[-8px] relative overflow-hidden group">
    {/* Subtle gradient background that reveals on hover */}
    <div className={`absolute inset-0 bg-gradient-to-br ${color} opacity-0 group-hover:opacity-5 transition-opacity duration-500`}></div>
    
    {/* Icon with gradient background */}
    <div className={`relative w-14 h-14 rounded-2xl bg-gradient-to-br ${color} flex items-center justify-center mb-6 p-3`}>
      <Icon className="w-full h-full text-white" />
    </div>
    
    <h3 className="text-xl font-medium mb-3 text-apple-50 group-hover:text-white transition-colors duration-300">{title}</h3>
    <p className="text-apple-300 text-base leading-relaxed">{description}</p>
    
    {/* Subtle line separator */}
    <div className="h-px w-16 bg-gradient-to-r from-transparent via-apple-600/40 to-transparent my-6"></div>
  </div>
);

const EnterpriseIntegration = () => {
  return (
    <section id="integration" className="mb-16">
      {/* Section header */}
      <div className="text-center mb-12">
        <div className="inline-block mb-4">
          <div className="flex items-center justify-center p-1.5 rounded-full bg-sortio-secondary/10 px-4 py-1.5">
            <Sparkles className="mr-2 text-sortio-secondary" size={14} />
            <span className="text-xs font-medium uppercase tracking-wider text-sortio-secondary">Seamless Integration</span>
          </div>
        </div>
        <h2 className="text-4xl font-semibold mb-5 tracking-tight text-apple-50">
          Enterprise <span className="apple-gradient-text">Integration Capabilities</span>
        </h2>
        <p className="text-apple-300 text-lg mx-auto font-light max-w-2xl mb-10">
          Connect Sortio with your existing enterprise systems and cloud storage solutions
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-10">
        <IntegrationCard 
          icon={Cloud}
          title="Cloud Storage"
          description="Integrate with SharePoint, OneDrive, Google Drive, and more"
        />
        
        <IntegrationCard 
          icon={Code}
          title="API Access"
          description="Robust API for integration with other enterprise systems"
          color="from-sortio-primary to-sortio-primary/70"
        />
        
        <IntegrationCard 
          icon={Zap}
          title="MCP Server"
          description="Connect with other tools and integrations through our MCP server"
        />
      </div>
      
      <div className="bg-apple-700/40 p-8 rounded-3xl border border-apple-600/30">
        <h3 className="text-xl font-medium mb-3">Custom Integration Solutions</h3>
        <p className="text-apple-300">
          Our enterprise team can work with you to create custom integrations for your specific needs. 
          Contact us to discuss your requirements.
        </p>
      </div>
    </section>
  );
};

export default EnterpriseIntegration;