import React, { useState } from 'react';
import AnimatedBackground from './AnimatedBackground';
import { ChevronDown, ChevronUp, HelpCircle } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 transition-all duration-300 transform hover:translate-x-1">
      <div className={`apple-panel transition-all duration-300 ${isOpen ? 'ring-1 ring-sortio-primary/30' : ''}`}>
        <button
          className="flex justify-between items-center w-full p-5 text-left rounded-apple focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="text-base font-medium text-apple-50">{question}</span>
          <div className={`flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center transition-colors duration-300 ${isOpen ? 'bg-sortio-primary/20' : 'bg-apple-600/40'}`}>
            {isOpen ? 
              <ChevronUp className="w-3.5 h-3.5 text-sortio-primary transition-transform duration-300" /> : 
              <ChevronDown className="w-3.5 h-3.5 text-apple-300 transition-transform duration-300" />}
          </div>
        </button>
        <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
          <div className="p-5 pt-0">
            <p className="text-apple-300 text-sm leading-relaxed">{answer}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const FAQsSection = () => {
  const faqs = [
    {
      question: "Do you offer a free trial?",
      answer: "We don't currently offer a free trial as Sortio is now available as a lifetime deal for just $12.99. This one-time payment gives you permanent access to all features."
    },
    {
      question: "Can I revert changes made by Sortio?",
      answer: "Yes, Sortio backs up your files before making any changes. You can easily revert to the original organization at any time from within the app."
    },
    {
      question: "Are there any recurring fees?",
      answer: "No, Sortio is available as a one-time purchase of $12.99. There are no recurring fees, subscriptions, or hidden charges."
    },
    {
      question: "How does the lifetime deal work?",
      answer: "Sortio is available as a one-time purchase of $12.99 for lifetime access. Once you pay, you'll have access to all features forever, with no recurring charges or subscription fees."
    },
    {
      question: "How do Smart Folders work?",
      answer: "Smart Folders are specific folders you can configure that automatically sort and organize new files as they arrive. Simply set up a Smart Folder once, and any files dropped into it will be automatically organized according to your preferences, keeping your digital workspace tidy without manual effort."
    },
    {
      question: "How does activity logging work?",
      answer: "Sortio maintains detailed logs of all file operations, including what files were moved, when the movement occurred, and where they were relocated to. These logs are securely stored and provide a complete history of file organization while ensuring your privacy."
    },
    {
      question: "Can I use Sortio on multiple devices?",
      answer: "Yes, your Sortio lifetime purchase allows you to use the app on multiple devices."
    },
    {
      question: "How does the AI-powered sorting work?",
      answer: "Sortio uses advanced machine learning algorithms to understand the context of your files. It then organizes them based on your instructions or preferences."
    },
    {
      question: "Is there a limit to how many files I can sort?",
      answer: "There's no limit to the number of files you can sort with Sortio. However, very large directories may take longer to process."
    }
  ];

  return (
    <section id="faqs" className="relative overflow-hidden py-16 sm:py-20 px-6" style={{ background: "#000000" }}>
      {/* Section separator */}
      <div className="absolute top-0 inset-x-0 h-px bg-gradient-to-r from-transparent via-apple-600/40 to-transparent"></div>
      
      <div className="container mx-auto px-4 max-w-4xl relative z-10">
        <div className="flex flex-col items-center mb-12">
          <div className="mb-4 rounded-full bg-apple-700/60 backdrop-blur-sm p-2 border border-apple-600/20">
            <HelpCircle className="w-6 h-6 text-sortio-primary" />
          </div>
          <h2 className="text-4xl lg:text-5xl font-bold text-center">
            Frequently Asked <span className="apple-gradient-text">Questions</span>
          </h2>
          <p className="mt-4 text-apple-300 max-w-xl text-center">
            Everything you need to know about Sortio. Can't find the answer you're looking for? 
            <a href="#contact" className="text-sortio-primary hover:text-sortio-primary/80 ml-1 transition-colors">
              Contact our team
            </a>
          </p>
        </div>
        
        <div className="space-y-4 custom-scrollbar">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
        
        {/* Support callout */}
        <div className="mt-12 apple-panel p-6 rounded-apple overflow-hidden relative">
          <div className="absolute -top-24 -right-24 w-48 h-48 bg-sortio-primary/20 rounded-full blur-3xl"></div>
          <div className="relative flex flex-col md:flex-row items-center justify-between gap-6 z-10">
            <div>
              <h3 className="text-xl font-medium text-apple-50">Still have questions?</h3>
              <p className="text-apple-300 mt-1">Our support team is just a click away</p>
            </div>
            <a href="#contact" className="apple-button whitespace-nowrap">Contact Support</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQsSection;