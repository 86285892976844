import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, ExternalLink, Download } from 'lucide-react';

const Footer = () => (
  <footer className="relative overflow-hidden py-16 border-t border-apple-700/20 bg-black">
    {/* Background blur orbs */}
    <div className="absolute -bottom-32 -left-32 w-64 h-64 bg-sortio-primary/5 rounded-full blur-3xl opacity-70"></div>
    <div className="absolute -top-32 -right-32 w-64 h-64 bg-sortio-secondary/5 rounded-full blur-3xl opacity-70"></div>
    
    <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
      {/* Top section with logo and link groups */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-12">
        {/* Logo and description */}
        <div className="md:col-span-4 flex flex-col items-center md:items-start">
          <div className="flex items-center mb-6">
            <img src="/logo.png" alt="Sortio Logo" className="h-8 w-auto" />
            <span className="ml-2 text-xl font-medium bg-clip-text text-transparent bg-gradient-to-r from-apple-50 to-apple-200">Sortio</span>
          </div>
          <p className="text-apple-300 text-sm leading-relaxed">
            AI-powered file organization tool that helps you manage your digital life more efficiently.
          </p>
          
          <Link to="/#pricing" className="mt-6 inline-flex items-center px-4 py-2 rounded-full bg-apple-700/40 border border-apple-600/30 text-sm text-apple-100 hover:bg-apple-600/50 transition-all duration-300 group">
            <Download className="w-4 h-4 mr-2 text-sortio-primary group-hover:text-sortio-primary/80" />
            <span>Get Sortio</span>
          </Link>
        </div>
        
        {/* Quick Links */}
        <div className="md:col-span-2 flex flex-col items-center md:items-start">
          <h3 className="text-apple-100 font-medium mb-5 text-sm uppercase tracking-wider">Product</h3>
          <ul className="space-y-3 text-center md:text-left">
            {[
              { to: "/#demo", label: "Demo" },
              { to: "/#features", label: "Features" },
              { to: "/#pricing", label: "Pricing" },
              { to: "/#faqs", label: "FAQs" },
              { to: "/business", label: "Sortio Business" },
            ].map((link, index) => (
              <li key={index}>
                <Link to={link.to} className="text-apple-300 hover:text-sortio-primary transition-colors text-sm inline-flex items-center group">
                  <span>{link.label}</span>
                  <ChevronRight className="w-3 h-3 ml-1 opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        
        {/* Support */}
        <div className="md:col-span-2 flex flex-col items-center md:items-start">
          <h3 className="text-apple-100 font-medium mb-5 text-sm uppercase tracking-wider">Support</h3>
          <ul className="space-y-3 text-center md:text-left">
            {[
              { to: "/#contact", label: "Contact" },
              { to: "/download", label: "Installation" },
              { to: "/#faqs", label: "Help Center" },
            ].map((link, index) => (
              <li key={index}>
                {link.to ? (
                  <Link 
                    to={link.to}
                    className="text-apple-300 hover:text-sortio-primary transition-colors text-sm inline-flex items-center group"
                  >
                    <span>{link.label}</span>
                    <ChevronRight className="w-3 h-3 ml-1 opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300" />
                  </Link>
                ) : (
                  <a 
                    href={link.href} 
                    className="text-apple-300 hover:text-sortio-primary transition-colors text-sm inline-flex items-center group"
                    target={link.external ? "_blank" : undefined}
                    rel={link.external ? "noopener noreferrer" : undefined}
                  >
                    <span>{link.label}</span>
                    {link.external ? (
                      <ExternalLink className="w-3 h-3 ml-1" />
                    ) : (
                      <ChevronRight className="w-3 h-3 ml-1 opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300" />
                    )}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
        
        {/* Legal */}
        <div className="md:col-span-2 flex flex-col items-center md:items-start">
          <h3 className="text-apple-100 font-medium mb-5 text-sm uppercase tracking-wider">Legal</h3>
          <ul className="space-y-3 text-center md:text-left">
            {[
              { to: "/about-us", label: "About Us" },
              { to: "/terms-and-conditions", label: "Terms & Conditions" },
              { to: "/privacy-policy", label: "Privacy Policy" },
            ].map((link, index) => (
              <li key={index}>
                <Link to={link.to} className="text-apple-300 hover:text-sortio-primary transition-colors text-sm inline-flex items-center group">
                  <span>{link.label}</span>
                  <ChevronRight className="w-3 h-3 ml-1 opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300" />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        
        {/* Download */}
        <div className="md:col-span-2 flex flex-col items-center md:items-start">
          <h3 className="text-apple-100 font-medium mb-5 text-sm uppercase tracking-wider">Download</h3>
          <div className="space-y-3">
            <Link to="/download" className="inline-flex items-center px-3 py-1.5 rounded-full bg-apple-700/40 border border-apple-600/30 text-sm text-apple-200 hover:text-sortio-primary transition-all duration-300">
              <svg className="w-4 h-4 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M12 3a9 9 0 0 0-9 9v7c0 1.1.9 2 2 2h4v-8H5v-1a7 7 0 0 1 14 0v1h-4v8h4c1.1 0 2-.9 2-2v-7a9 9 0 0 0-9-9z"/>
              </svg>
              <span>macOS</span>
            </Link>
            <Link to="/download" className="inline-flex items-center px-3 py-1.5 rounded-full bg-apple-700/40 border border-apple-600/30 text-sm text-apple-200 hover:text-sortio-primary transition-all duration-300">
              <svg className="w-4 h-4 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M20.998 8H3.002A2 2 0 0 0 1 10.008v7.984A2 2 0 0 0 3.002 20h17.996A2 2 0 0 0 23 17.992V10.008A2 2 0 0 0 20.998 8Z"/>
                <path d="M9 16l2-2 2 2"/>
                <path d="M12 14v4"/>
                <path d="M18 8V6a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v2"/>
              </svg>
              <span>Windows</span>
            </Link>
          </div>
        </div>
      </div>
      
      {/* Bottom copyright */}
      <div className="mt-12 pt-8 border-t border-apple-700/20 flex flex-col md:flex-row justify-between items-center">
        <p className="text-apple-400 text-sm">&copy; {new Date().getFullYear()} Sortio. All rights reserved.</p>
        
        {/* Made with love */}
        <div className="mt-4 md:mt-0">
          <span className="text-apple-400 text-xs">
            Made with <span className="text-sortio-accent">&hearts;</span> in California
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;