import React, { useEffect, useRef } from 'react';
import AnimatedBackground from './AnimatedBackground';

const TestimonialsSection = () => {
  const scrollRef = useRef(null);
  
  // Real testimonials from users
  const testimonials = [
    {
      message: "Thanks, this looks like just what I was after! Thanks for providing the link.",
      name: "u/Creepy_Distance_3341",
    },
    {
      message: "I NEED THIS. OMG [...] So far I am LOVING it! It's exactly what I needed for my Desktop, which is an utter mess at the moment! You made an amazing app!",
      name: "u/DaeOnReddit",
    },
    {
      message: "This is fantastic. Should definitely be a standard utility.",
      name: "u/Round_Tomorrow5564",
    },
    {
      message: "Thanks - this is an interesting App. I have tons of documentation from my work (~100 GB of emails in PST files that has variety of different attachments). [...] if yes, this might be a killer app!",
      name: "u/Left-Leopard-1266",
    },
    {
      message: "OMG! works like a charm!",
      name: "u/slasho2k5",
    },
    {
      message: "Great software - [...] it works really nicely indeed.",
      name: "u/AnyMeaning1888",
    },
    {
      message: "it is a great app!",
      name: "u/wolfgang263",
    },
    {
      message: "The thing is super cool...",
      name: "u/Extra-Virus9958",
    },
    {
      message: "nice tool, 🔥🔥",
      name: "u/crackdepirate",
    },
    {
      message: "This is great. So many cool projects on here. You all are impressive.",
      name: "u/HolyWrath",
    },
    {
      message: "That looks lightweight, smooth, and responsive.",
      name: "u/path0l0gy",
    },
    {
      message: "This is pretty cool.",
      name: "u/slashbackslash",
    },
    {
      message: "Apps like these will be so much better when apple provides native LLM APIs to use at an OS level.",
      name: "u/apexkid1",
    },
    {
      message: "Hey that's cool! What did you use to make that interface?",
      name: "u/path0l0gy",
    },
    {
      message: "Looks good. I'll give it a go. Kudos for not developing an app on Electron.",
      name: "u/SeniorSesameRocker",
    },
    {
      message: "cool how can i stay updated about its dev?",
      name: "u/Silly-Fall-393",
    },
    {
      message: "I like the pricing...",
      name: "u/Left-Leopard-1266",
    },
  ];

  // Auto-scrolling effect
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;
    
    let scrollAmount = 0;
    const cardWidth = 320; // Width + margin (w-80 + mr-6 = 320px)
    const totalWidth = cardWidth * testimonials.length;
    const scrollSpeed = 0.5; // pixels per frame - reduced for slower scrolling
    let animationId;
    let isPaused = false;
    
    const scroll = () => {
      if (isPaused) {
        animationId = requestAnimationFrame(scroll);
        return;
      }
      
      scrollAmount += scrollSpeed;
      
      // Reset when we've scrolled through all items
      if (scrollAmount >= totalWidth) {
        scrollAmount = 0;
      }
      
      scrollContainer.scrollLeft = scrollAmount;
      animationId = requestAnimationFrame(scroll);
    };
    
    // Start the animation
    animationId = requestAnimationFrame(scroll);
    
    // Pause scrolling when hovering
    const handleMouseEnter = () => { isPaused = true; };
    const handleMouseLeave = () => { isPaused = false; };
    
    scrollContainer.addEventListener('mouseenter', handleMouseEnter);
    scrollContainer.addEventListener('mouseleave', handleMouseLeave);
    
    // Clean up
    return () => {
      cancelAnimationFrame(animationId);
      scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
      scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [testimonials.length]);

  return (
    <section id="testimonials" className="relative overflow-hidden py-24" style={{ background: "#000000" }}>
      {/* Section separator */}
      <div className="absolute top-0 inset-x-0 h-px bg-gradient-to-r from-transparent via-apple-600/40 to-transparent"></div>
      
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="text-center mb-14">
          {/* Updated heading style to match other sections */}
          <div className="inline-block">
            <div className="flex flex-col items-center">
              <h2 className="text-4xl md:text-5xl font-bold mb-2 tracking-tight bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600 bg-clip-text text-transparent">
                What People Are Saying
              </h2>
              <div className="h-1 w-16 bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-600 rounded-full"></div>
            </div>
          </div>
          <p className="text-lg text-gray-400 max-w-2xl mx-auto mt-6">
            Join thousands who've revolutionized their file management
          </p>
        </div>
        
        <div className="relative">
          {/* Custom scrollbar styling - hidden for smoother look with auto-scroll */}
          <style jsx="true">{`
            .testimonial-scroll::-webkit-scrollbar {
              height: 0;
              display: none;
            }
            .testimonial-scroll {
              -ms-overflow-style: none;
              scrollbar-width: none;
            }
          `}</style>
          
          {/* Scroll container - added ref for auto-scrolling */}
          <div 
            ref={scrollRef}
            className="testimonial-scroll flex overflow-x-auto pb-6 snap-x"
          >
            {/* Duplicate testimonials at the beginning to create a seamless loop effect */}
            {testimonials.map((testimonial, index) => (
              <div 
                key={`original-${index}`} 
                className="flex-shrink-0 w-80 mr-6"
              >
                <div className="apple-panel p-6 rounded-2xl backdrop-blur-md h-full flex flex-col">
                  <div className="mb-4 flex-grow">
                    <p className="text-gray-200 italic">"{testimonial.message}"</p>
                  </div>
                  <div className="flex items-center mt-auto">
                    <div className="w-8 h-8 rounded-full bg-gradient-to-r from-orange-500 to-red-500 flex items-center justify-center text-white text-xs font-bold">
                      {testimonial.name.charAt(2).toUpperCase()}
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-200">{testimonial.name}</p>
                      <p className="text-xs text-gray-400">via Reddit</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            
            {/* Duplicate the testimonials to create a continuous scrolling effect */}
            {testimonials.map((testimonial, index) => (
              <div 
                key={`duplicate-${index}`} 
                className="flex-shrink-0 w-80 mr-6"
              >
                <div className="apple-panel p-6 rounded-2xl backdrop-blur-md h-full flex flex-col">
                  <div className="mb-4 flex-grow">
                    <p className="text-gray-200 italic">"{testimonial.message}"</p>
                  </div>
                  <div className="flex items-center mt-auto">
                    <div className="w-8 h-8 rounded-full bg-gradient-to-r from-orange-500 to-red-500 flex items-center justify-center text-white text-xs font-bold">
                      {testimonial.name.charAt(2).toUpperCase()}
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-200">{testimonial.name}</p>
                      <p className="text-xs text-gray-400">via Reddit</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Fade edges for better UX */}
          <div className="absolute top-0 bottom-0 left-0 w-16 bg-gradient-to-r from-black to-transparent pointer-events-none"></div>
          <div className="absolute top-0 bottom-0 right-0 w-16 bg-gradient-to-l from-black to-transparent pointer-events-none"></div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;