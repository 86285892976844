import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const BusinessHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`fixed top-0 w-full z-50 transition-all duration-300 ${
      scrolled ? 'business-glass-bg shadow-lg backdrop-blur-xl' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/" className="flex items-center group mr-2">
              <img src="/logo.png" alt="Sortio Logo" className="h-10 w-auto" />
              <div className="text-xl font-medium ml-2 text-apple-50 group-hover:text-sortio-primary transition-colors">Sortio</div>
            </Link>
            <span className="px-2 py-1 rounded-md bg-blue-600/20 text-blue-300 text-xs font-medium border border-blue-500/30">
              Business
            </span>
          </div>
          
          <button className="md:hidden text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              <li><a href="#overview" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Overview</a></li>
              <li><a href="#features" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Features</a></li>
              <li><a href="#integrations" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Integrations</a></li>
              <li><a href="#security" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Security</a></li>
              <li><a href="#pricing" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Pricing</a></li>
              <li><Link to="/" className="personal-button text-sm">Personal Edition</Link></li>
              <li><a href="#waitlist" className="business-cta-button text-sm">Join Waitlist</a></li>
            </ul>
          </nav>
        </div>
        
        {isMenuOpen && (
          <nav className="md:hidden mt-4 pb-4 animate-fadeIn">
            <ul className="space-y-4">
              <li><a href="#overview" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Overview</a></li>
              <li><a href="#features" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Features</a></li>
              <li><a href="#integrations" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Integrations</a></li>
              <li><a href="#security" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Security</a></li>
              <li><a href="#pricing" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Pricing</a></li>
              <li><Link to="/" className="personal-button inline-block" onClick={toggleMenu}>Personal Edition</Link></li>
              <li><a href="#waitlist" className="business-cta-button inline-block mt-2" onClick={toggleMenu}>Join Waitlist</a></li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default BusinessHeader;