// HeroSection.jsx
import React, { useRef, useMemo, Suspense, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { ChevronRight, ArrowRight } from 'lucide-react';
import * as THREE from 'three';

// Main HeroSection Component
const HeroSection = () => {
  const [scrollY, setScrollY] = useState(0);
  
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  const parallaxOffset = scrollY * 0.3; // Adjust the multiplier for parallax intensity
  
  return (
    <section className="relative min-h-screen flex items-center text-apple-50 overflow-hidden bg-black pt-[120px]">
      {/* Gradient Orbs - Background Effect */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-[10%] left-[15%] w-[35vw] h-[35vw] rounded-full bg-sortio-primary/20 blur-[120px] animate-float-slow" />
        <div className="absolute bottom-[10%] right-[15%] w-[40vw] h-[40vw] rounded-full bg-sortio-secondary/20 blur-[150px] animate-float" />
      </div>
      
      {/* 3D Canvas Background - Layer 1 */}
      <div className="absolute top-0 left-0 w-full h-full z-0 opacity-30">
        <Canvas
          camera={{ position: [0, 0, 10], fov: 50 }}
          style={{ background: 'transparent' }}
        >
          {/* Lighting */}
          <ambientLight intensity={0.3} />
          <directionalLight position={[5, 5, 5]} intensity={0.8} />

          {/* Suspense for lazy loading */}
          <Suspense fallback={null}>
            <AnimatedGrid />
            <SpawningFiles />
          </Suspense>
        </Canvas>
      </div>
      
      {/* Content Container */}
      <div className="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-20 mt-10 md:mt-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
          {/* Left Column - Content */}
          <div className="flex flex-col" style={{ transform: `translateY(${parallaxOffset * 0.5}px)` }}>
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-medium tracking-tight mb-6 leading-tight">
              <span className="apple-gradient-text">Organize</span> your files
              <br />like never before
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-apple-100 font-light max-w-md leading-relaxed">
              Sortio uses AI to organize your files intelligently, saving you hours of manual work
            </p>
            <div className="flex flex-col sm:flex-row gap-4 mt-2">
              <a 
                href="/download"
                className="apple-button text-base px-8 py-4 flex items-center justify-center"
              >
                Download Now <ArrowRight className="ml-2" size={18} />
              </a>
              <a 
                href="#demo"
                className="text-apple-50 hover:text-sortio-primary transition-all px-6 py-3 flex items-center justify-center border border-apple-300/30 rounded-full hover:border-sortio-primary"
              >
                See it in action <ChevronRight className="ml-1" size={18} />
              </a>
            </div>
          </div>
          
          {/* Right Column - App Image */}
          <div className="relative flex justify-center md:justify-end items-center" style={{ transform: `translateY(${parallaxOffset * 0.3}px)` }}>
            <div className="w-full max-w-2xl">
              <div className="relative z-10 transform transition-all duration-500">
                <img 
                  src="/hero.png" 
                  alt="Sortio application interface" 
                  className="w-full h-auto object-contain"
                  style={{
                    filter: 'drop-shadow(0 20px 40px rgba(0, 0, 0, 0.6))',
                  }}
                />
              </div>
              
              {/* Glow effect */}
              <div className="absolute inset-0 -z-10 bg-sortio-primary/20 rounded-full blur-[60px] opacity-70 animate-pulse-slow" />
            </div>
          </div>
        </div>
        
        {/* Scroll indicator */}
        <div className="hidden md:flex justify-center mt-16 md:mt-24 pb-8 animate-bounce">
          <div className="w-8 h-12 rounded-full border-2 border-apple-100/30 flex justify-center">
            <div className="w-1 h-3 bg-apple-100/50 rounded-full mt-2 animate-pulse-slow"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

// AnimatedGrid Component
const AnimatedGrid = () => {
  const groupRef = useRef();
  const numLines = 50; // Number of lines in each direction
  const spacing = 0.5; // Spacing between lines
  const speed = 0.01; // Speed of downward movement

  // Generate grid lines
  const lines = useMemo(() => {
    const positions = [];

    // Horizontal lines (parallel to X-axis)
    for (let i = -numLines; i <= numLines; i++) {
      positions.push(-numLines * spacing, i * spacing, 0);
      positions.push(numLines * spacing, i * spacing, 0);
    }

    // Vertical lines (parallel to Z-axis)
    for (let i = -numLines; i <= numLines; i++) {
      positions.push(i * spacing, -numLines * spacing, 0);
      positions.push(i * spacing, numLines * spacing, 0);
    }

    return new Float32Array(positions);
  }, [numLines, spacing]);

  // Create BufferGeometry
  const geometry = useMemo(() => {
    const geom = new THREE.BufferGeometry();
    geom.setAttribute('position', new THREE.BufferAttribute(lines, 3));
    return geom;
  }, [lines]);

  // Animation Loop
  useFrame(() => {
    if (groupRef.current) {
      // Move the group downward
      groupRef.current.position.y -= speed;

      // Reset position when it moves below a certain threshold
      if (groupRef.current.position.y < -spacing) {
        groupRef.current.position.y += spacing;
      }
    }
  });

  return (
    <group ref={groupRef}>
      <lineSegments geometry={geometry}>
        <lineBasicMaterial
          attach="material"
          color="#4A5568" // Subtle gray color
          transparent
          opacity={0.3} // Make it subtle
        />
      </lineSegments>
    </group>
  );
};

// SpawningFiles Component
const SpawningFiles = () => {
  const groupRef = useRef();
  const [files, setFiles] = useState([]);

  const spawnInterval = 2000; // Spawn a file every 2 seconds
  const fileSpeed = 0.02; // Speed at which files move downward
  const fileLifetime = 6000; // Time (ms) before a file is removed after spawning

  // Predefined muted color palette
  const mutedColors = [
    '#4A5568', // Gray
    '#2C7A7B', // Teal
    '#718096', // Blue Gray
    '#A0AEC0', // Light Gray
    '#CBD5E0', // Cool Gray
  ];

  // Function to spawn a new file
  const spawnFile = () => {
    const xRange = 40; // Adjust based on your grid size
    const x = (Math.random() - 0.5) * xRange * 2; // Random x between -xRange and xRange
    const zRange = 2; // Depth range if needed
    const z = (Math.random() - 0.5) * zRange * 2; // Random z between -zRange and zRange

    const newFile = {
      id: THREE.MathUtils.generateUUID(),
      position: new THREE.Vector3(x, 10, z), // Start above the view
      rotation: new THREE.Euler(
        Math.random() * Math.PI,
        Math.random() * Math.PI,
        Math.random() * Math.PI
      ),
      color: mutedColors[Math.floor(Math.random() * mutedColors.length)],
      createdAt: Date.now(),
    };

    setFiles((prevFiles) => [...prevFiles, newFile]);
  };

  // Spawn files at intervals
  useEffect(() => {
    const interval = setInterval(() => {
      spawnFile();
    }, spawnInterval);

    return () => clearInterval(interval);
  }, []);

  // Animation Loop
  useFrame(() => {
    setFiles((prevFiles) =>
      prevFiles
        .map((file) => {
          const newPosition = file.position.clone();
          newPosition.y -= fileSpeed;
          return { ...file, position: newPosition };
        })
        .filter((file) => file.position.y > -10) // Remove files that moved out of view
    );
  });

  return (
    <group ref={groupRef}>
      {files.map((file) => (
        <mesh key={file.id} position={file.position} rotation={file.rotation}>
          <boxGeometry args={[0.3, 0.3, 0.3]} />
          <meshStandardMaterial color={file.color} />
        </mesh>
      ))}
    </group>
  );
};
