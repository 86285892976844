import React from 'react';
import AnimatedBackground from './AnimatedBackground';
import { FolderSearch, Zap, Wand2, Fingerprint, Sparkles, Edit3, ChevronRight } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description, comingSoon = false, gradient = "from-sortio-primary to-sortio-primary/70" }) => (
  <div className="bg-apple-700/40 backdrop-blur-md p-8 rounded-3xl border border-apple-600/30 transition-all duration-500 
                  hover:translate-y-[-8px] relative overflow-hidden group">
    {/* Subtle gradient background that reveals on hover */}
    <div className={`absolute inset-0 bg-gradient-to-br ${gradient} opacity-0 group-hover:opacity-5 transition-opacity duration-500`}></div>
    
    {/* Coming soon badge */}
    {comingSoon && (
      <div className="absolute top-6 right-6 px-3 py-1 rounded-full bg-sortio-primary/10 border border-sortio-primary/20">
        <span className="text-xs font-medium text-sortio-primary">Coming Soon</span>
      </div>
    )}
    
    {/* Icon with gradient background */}
    <div className={`relative w-14 h-14 rounded-2xl bg-gradient-to-br ${gradient} flex items-center justify-center mb-6 p-3`}>
      <Icon className="w-full h-full text-white" />
    </div>
    
    <h3 className="text-xl font-medium mb-3 text-apple-50 group-hover:text-white transition-colors duration-300">{title}</h3>
    <p className="text-apple-300 text-base leading-relaxed mb-6">{description}</p>
    
  </div>
);

const FeaturesSection = () => (
  <section id="features" className="py-36 relative overflow-hidden" style={{ backgroundColor: "#121212", backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.03) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.03) 1px, transparent 1px)", backgroundSize: "20px 20px" }}>
    {/* Section borders */}
    <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-apple-600/40 to-transparent" />
    <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-apple-600/40 to-transparent" />
    
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Section header */}
      <div className="text-center mb-20">
        <div className="inline-block mb-4">
          <div className="flex items-center justify-center p-1.5 rounded-full bg-sortio-secondary/10 px-4 py-1.5">
            <Sparkles className="mr-2 text-sortio-secondary" size={14} />
            <span className="text-xs font-medium uppercase tracking-wider text-sortio-secondary">Advanced Features</span>
          </div>
        </div>
        <h2 className="text-4xl md:text-5xl font-semibold mb-5 tracking-tight text-apple-50">
          Designed for <span className="apple-gradient-text">Productivity</span>
        </h2>
        <p className="text-apple-300 text-lg max-w-2xl mx-auto font-light">
          Powerful tools that help you organize your digital life with ease and precision
        </p>
      </div>
      
      {/* Features grid */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <FeatureCard
          icon={FolderSearch}
          title="Intelligent Sorting"
          description="Our AI-powered system organizes your files according to your instructions. Simply describe your desired organization, and watch the magic happen."
          gradient="from-sortio-primary to-sortio-primary/70"
        />
        <FeatureCard
          icon={Zap}
          title="Lightning-Fast Processing"
          description="Experience rapid file organization with our optimized engine, handling thousands of files and large directories with remarkable speed."
          gradient="from-[#FF9F0A] to-[#FF3B30]"
        />
        <FeatureCard
          icon={Wand2}
          title="Smart Suggestions"
          description="Receive intelligent recommendations for file organization based on your past sorting patterns and content analysis."
          gradient="from-sortio-accent to-purple-500"
        />
        <FeatureCard
          icon={Fingerprint}
          title="Bring Your Own Key, including local LLM"
          description="Use your own API keys or even run with a local LLM for complete privacy and control over your data processing."
          gradient="from-sortio-secondary to-teal-500"
          comingSoon={true}
        />
        <FeatureCard
          icon={Sparkles}
          title="Smart Folders"
          description="Set up dedicated folders that automatically sort and organize new files as they arrive, keeping your digital workspace tidy effortlessly."
          gradient="from-blue-500 to-sortio-primary"
        />
        <FeatureCard
          icon={Edit3}
          title="Secure Activity Logging"
          description="Track every file movement with detailed logs showing what happened to each file, when it moved, and where it went. All logs are stored securely."
          gradient="from-sortio-accent to-red-600"
        />
      </div>
      
      {/* Testimonial/Featured section */}
      <div className="mt-24 flex flex-col items-center">
        <div className="apple-panel max-w-3xl mx-auto p-8 rounded-3xl flex flex-col items-center">
          <p className="text-apple-200 text-sm font-medium mb-4 uppercase tracking-wider">Featured On</p>
          <a 
            href="https://theresanaiforthat.com/ai/sortio/?ref=featured&v=2652768" 
            target="_blank" 
            rel="nofollow"
            className="transition-transform hover:scale-105 duration-300"
          >
            <img 
              width="280" 
              src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600" 
              alt="Featured on There's an AI for That" 
              className="rounded-lg"
              style={{ filter: 'drop-shadow(0 10px 20px rgba(0, 0, 0, 0.3))' }}
            />
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default FeaturesSection;