import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`fixed top-0 w-full z-50 transition-all duration-300 ${
      scrolled ? 'apple-glass-bg shadow-lg backdrop-blur-xl' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/" className="flex items-center group mr-2">
              <img src="/logo.png" alt="Sortio Logo" className="h-10 w-auto" />
              <div className="text-xl font-medium ml-2 text-apple-50 group-hover:text-sortio-primary transition-colors">Sortio</div>
            </Link>
            <span className="px-2 py-1 rounded-md bg-sortio-primary/20 text-sortio-primary/90 text-xs font-medium border border-sortio-primary/30">
              Personal
            </span>
          </div>
          
          <button className="md:hidden text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              <li><a href="/#demo" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Demo</a></li>
              <li><a href="/#security" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Security</a></li>
              <li><a href="/#features" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Features</a></li>
              <li><a href="/#pricing" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Pricing</a></li>
              <li><a href="/#faqs" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">FAQs</a></li>
              <li><Link to="/articles" className="text-apple-50 hover:text-sortio-primary transition-colors text-sm font-medium">Articles</Link></li>
              <li><a href="/business" className="business-button text-sm">For Business</a></li>
              <li><Link to="/download" className="apple-button text-sm">Download</Link></li>
            </ul>
          </nav>
        </div>
        
        {isMenuOpen && (
          <nav className="md:hidden mt-4 pb-4 animate-fadeIn">
            <ul className="space-y-4">
              <li><a href="/#demo" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Demo</a></li>
              <li><a href="/#security" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Security</a></li>
              <li><a href="/#features" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Features</a></li>
              <li><a href="/#pricing" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Pricing</a></li>
              <li><a href="/#faqs" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>FAQs</a></li>
              <li><Link to="/articles" className="block text-apple-50 hover:text-sortio-primary transition-colors" onClick={toggleMenu}>Articles</Link></li>
              <li><a href="/business" className="business-button inline-block" onClick={toggleMenu}>For Business</a></li>
              <li><Link to="/download" className="block apple-button inline-block mt-2" onClick={toggleMenu}>Download</Link></li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;