import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Users, Rocket, FileSearch, Target, Download, MessageCircle, ChevronLeft, Heart } from 'lucide-react';

const AboutUsPage = () => {
  const valueCards = [
    {
      title: "Simplicity",
      description: "We believe technology should simplify your life, not complicate it.",
      icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
      </svg>,
      color: "from-sortio-primary/10 to-sortio-primary/5",
      iconColor: "text-sortio-primary"
    },
    {
      title: "Privacy",
      description: "Your data is yours. We design our products with privacy at the forefront.",
      icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
      </svg>,
      color: "from-sortio-secondary/10 to-sortio-secondary/5",
      iconColor: "text-sortio-secondary"
    },
    {
      title: "Innovation",
      description: "We're constantly pushing the boundaries of what's possible with AI and file management.",
      icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
      </svg>,
      color: "from-sortio-accent/10 to-sortio-accent/5",
      iconColor: "text-sortio-accent"
    },
    {
      title: "User-Centric",
      description: "Everything we do is with our users in mind. Your feedback drives our development.",
      icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>,
      color: "from-blue-500/10 to-blue-500/5",
      iconColor: "text-blue-500"
    }
  ];

  return (
    <div className="min-h-screen bg-black text-apple-100 py-24">
      <Helmet>
        <title>About Us - Sortio</title>
        <meta name="description" content="Learn about Sortio, the AI-powered file organization app, and our mission to simplify digital organization." />
      </Helmet>
      
      {/* Background gradient */}
      <div className="absolute top-0 inset-x-0 h-[500px] bg-gradient-to-b from-apple-700/10 to-transparent"></div>
      <div className="absolute -top-[600px] -right-[300px] w-[1000px] h-[1000px] bg-sortio-primary/5 rounded-full blur-3xl"></div>
      <div className="absolute -bottom-[600px] -left-[300px] w-[1000px] h-[1000px] bg-sortio-secondary/5 rounded-full blur-3xl"></div>
      
      <div className="container mx-auto px-6 max-w-4xl relative z-10">
        {/* Back button */}
        <Link to="/" className="inline-flex items-center text-apple-300 hover:text-sortio-primary mb-12 transition-colors group">
          <ChevronLeft className="w-4 h-4 mr-1 group-hover:-translate-x-1 transition-transform" />
          <span>Back to Home</span>
        </Link>
        
        {/* Header */}
        <div className="mb-16 flex flex-col items-center">
          <div className="w-14 h-14 rounded-full bg-apple-700/60 backdrop-blur-sm flex items-center justify-center mb-6 border border-apple-600/30">
            <Users className="w-6 h-6 text-sortio-primary" />
          </div>
          <h1 className="text-4xl lg:text-5xl font-bold text-center">
            About <span className="apple-gradient-text">Sortio</span>
          </h1>
          <p className="text-apple-300 mt-4 text-center max-w-xl">
            We're building the future of intelligent file organization.
          </p>
        </div>
        
        {/* Content */}
        <div className="space-y-24">
          {/* Our Mission */}
          <section className="relative">
            <div className="absolute -right-16 top-0 w-32 h-32 rounded-full bg-sortio-primary/10 blur-3xl opacity-50"></div>
            <div className="apple-panel p-8 rounded-apple backdrop-blur-sm relative overflow-hidden">
              <div className="flex flex-col md:flex-row items-start gap-6">
                <div className="w-14 h-14 rounded-full bg-sortio-primary/20 flex items-center justify-center flex-shrink-0 mb-4 md:mb-0">
                  <Rocket className="w-6 h-6 text-sortio-primary" />
                </div>
                <div>
                  <h2 className="text-2xl font-semibold text-apple-50 mb-4">Our Mission</h2>
                  <p className="text-apple-300 leading-relaxed">
                    At Sortio, we're on a mission to revolutionize the way people organize their digital lives. We believe that with the power of AI, we can make file management effortless and intuitive for everyone.
                  </p>
                </div>
              </div>
            </div>
          </section>
          
          {/* Our Story */}
          <section className="relative">
            <div className="absolute -left-16 top-0 w-32 h-32 rounded-full bg-sortio-secondary/10 blur-3xl opacity-50"></div>
            <div className="apple-panel p-8 rounded-apple backdrop-blur-sm relative overflow-hidden">
              <div className="flex flex-col md:flex-row items-start gap-6">
                <div className="w-14 h-14 rounded-full bg-sortio-secondary/20 flex items-center justify-center flex-shrink-0 mb-4 md:mb-0">
                  <Heart className="w-6 h-6 text-sortio-secondary" />
                </div>
                <div>
                  <h2 className="text-2xl font-semibold text-apple-50 mb-4">Our Story</h2>
                  <p className="text-apple-300 leading-relaxed">
                    Sortio was born out of a simple frustration: the endless hours spent organizing files and folders. Our founders, a team of AI enthusiasts and software engineers, came together with a vision to create a tool that would make file organization as easy as having a conversation.
                  </p>
                </div>
              </div>
            </div>
          </section>
          
          {/* What We Do */}
          <section className="relative">
            <div className="absolute -right-16 top-0 w-32 h-32 rounded-full bg-sortio-accent/10 blur-3xl opacity-50"></div>
            <div className="apple-panel p-8 rounded-apple backdrop-blur-sm relative overflow-hidden">
              <div className="flex flex-col md:flex-row items-start gap-6">
                <div className="w-14 h-14 rounded-full bg-sortio-accent/20 flex items-center justify-center flex-shrink-0 mb-4 md:mb-0">
                  <FileSearch className="w-6 h-6 text-sortio-accent" />
                </div>
                <div>
                  <h2 className="text-2xl font-semibold text-apple-50 mb-4">What We Do</h2>
                  <p className="text-apple-300 leading-relaxed">
                    Sortio is an AI-powered application that organizes your files based on natural language instructions. Simply tell Sortio how you want your files organized, and watch as it intelligently sorts and categorizes your digital content.
                  </p>
                </div>
              </div>
            </div>
          </section>
          
          {/* Our Values */}
          <section>
            <div className="text-center mb-12">
              <div className="inline-block p-2 rounded-full bg-apple-700/40 backdrop-blur-sm mb-4">
                <Target className="w-6 h-6 text-sortio-primary" />
              </div>
              <h2 className="text-2xl font-semibold text-apple-50 mb-2">Our Values</h2>
              <p className="text-apple-300 max-w-lg mx-auto">What drives us every day in our mission to make digital organization effortless.</p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {valueCards.map((card, index) => (
                <div key={index} className={`p-6 rounded-apple bg-gradient-to-br ${card.color} border border-apple-600/30 backdrop-blur-sm`}>
                  <div className={`w-12 h-12 rounded-full bg-apple-700/40 flex items-center justify-center mb-4 ${card.iconColor}`}>
                    {card.icon}
                  </div>
                  <h3 className="text-xl font-medium text-apple-50 mb-2">{card.title}</h3>
                  <p className="text-apple-300">{card.description}</p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Join Us */}
          <section className="apple-panel p-8 rounded-apple backdrop-blur-sm relative overflow-hidden">
            <div className="absolute -top-24 -right-24 w-48 h-48 bg-gradient-to-br from-sortio-primary/20 to-sortio-secondary/20 rounded-full blur-3xl"></div>
            
            <div className="relative">
              <h2 className="text-2xl font-semibold text-apple-50 mb-4">Join Us on Our Journey</h2>
              <p className="text-apple-300 leading-relaxed mb-4">
                We're just getting started, and we're excited to have you with us on this journey. Whether you're a tech enthusiast, a busy professional, or someone who just wants a tidier digital life, Sortio is here to help.
              </p>
              <p className="text-apple-300 leading-relaxed mb-8">
                Ready to experience the future of file organization? 
                <Link to="/download" className="text-sortio-primary hover:text-sortio-primary/80 transition-colors font-medium ml-1">
                  Download Sortio
                </Link> 
                today and see the difference for yourself.
              </p>
              
              <div className="flex flex-col sm:flex-row gap-4">
                <Link to="/download" className="apple-button inline-flex items-center justify-center">
                  <Download className="w-5 h-5 mr-2" />
                  <span>Download Now</span>
                </Link>
                <Link to="/#contact" className="inline-flex items-center justify-center px-5 py-3 rounded-full border border-apple-600/40 bg-apple-700/40 text-apple-100 hover:bg-apple-600/50 transition-all">
                  <MessageCircle className="w-5 h-5 mr-2 text-sortio-primary" />
                  <span>Contact Us</span>
                </Link>
              </div>
            </div>
          </section>
        </div>
        
        {/* Back to top button */}
        <div className="flex justify-center mt-16 mb-8">
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="inline-flex items-center px-4 py-2 rounded-full bg-apple-700/40 border border-apple-600/30 text-sm text-apple-300 hover:text-sortio-primary transition-colors"
          >
            <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="m18 15-6-6-6 6"/>
            </svg>
            <span>Back to top</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;