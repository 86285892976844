import React from 'react';
import { Helmet } from 'react-helmet';
import AnimatedBackground from '../components/AnimatedBackground';
import ArticleStructuredData from '../components/ArticleStructuredData';
import BusinessHeader from '../components/BusinessHeader';
import DemoSection from '../components/DemoSection';

// Business page components
import BusinessHero from './business/BusinessHero';
import BusinessOverview from './business/BusinessOverview';
import EnterpriseFeatures from './business/EnterpriseFeatures';
import EnterpriseIntegration from './business/EnterpriseIntegration';
import AdvancedSecurity from './business/AdvancedSecurity';
import RuleBasedSorting from './business/RuleBasedSorting';
import BusinessPricing from './business/BusinessPricing';
import CompetitivePositioning from './business/CompetitivePositioning';
import WaitlistSignup from './business/WaitlistSignup';

const BusinessPage = () => {
  return (
    <>
      <Helmet>
        <title>Sortio Business - Enterprise File Organization Solution</title>
        <meta name="description" content="Sortio Business is an intelligent file organization solution for enterprises. Deploy AI-powered file management across your organization with advanced security and integration capabilities." />
      </Helmet>
      <ArticleStructuredData
        title="Sortio Business - Enterprise File Organization Solution"
        description="Sortio Business is an intelligent file organization solution for enterprises. Deploy AI-powered file management across your organization with advanced security and integration capabilities."
        datePublished="2023-01-01T00:00:00.000Z"
        dateModified="2023-01-01T00:00:00.000Z"
        authorName="Sortio Team"
      />

      <BusinessHeader />
      <BusinessHero />

      <div className="relative">
        <AnimatedBackground variant="business" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 relative z-10">
          <div className="">
            {/* Main Content */}
            <div className="prose prose-invert max-w-none">
              <DemoSection />

              <section id="overview">
                <BusinessOverview />
              </section>
              
              <section id="features">
                <EnterpriseFeatures />
              </section>
              
              <section id="integrations">
                <EnterpriseIntegration />
              </section>
              
              <section id="security">
                <AdvancedSecurity />
                <RuleBasedSorting />
              </section>
              
              <section id="pricing">
                <BusinessPricing />
                <CompetitivePositioning />
              </section>
              
              <section id="waitlist">
                <WaitlistSignup />
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessPage;