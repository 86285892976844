import React from 'react';
import { Server, Users, Clipboard, Network, Monitor, Laptop, Globe, HardDrive } from 'lucide-react';

const EnterpriseFeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-apple-700/40 backdrop-blur-md p-8 rounded-3xl border border-apple-600/30 transition-all duration-500 
                  hover:translate-y-[-8px] relative overflow-hidden group">
    {/* Subtle gradient background that reveals on hover */}
    <div className="absolute inset-0 bg-gradient-to-br from-sortio-primary to-sortio-primary/70 opacity-0 group-hover:opacity-5 transition-opacity duration-500"></div>
    
    {/* Icon with gradient background */}
    <div className="relative w-14 h-14 rounded-2xl bg-gradient-to-br from-sortio-primary to-sortio-primary/70 flex items-center justify-center mb-6 p-3">
      <Icon className="w-full h-full text-white" />
    </div>
    
    <h3 className="text-xl font-medium mb-3 text-apple-50 group-hover:text-white transition-colors duration-300">{title}</h3>
    <p className="text-apple-300 text-base leading-relaxed">{description}</p>
    
    {/* Subtle line separator */}
    <div className="h-px w-16 bg-gradient-to-r from-transparent via-apple-600/40 to-transparent my-6"></div>
  </div>
);

const EnterpriseFeatures = () => {
  return (
    <section id="enterprise-features" className="mb-16">
      {/* Section header */}
      <div className="text-center mb-12">
        <div className="inline-block mb-4">
          <div className="flex items-center justify-center p-1.5 rounded-full bg-sortio-primary/10 px-4 py-1.5">
            <Server className="mr-2 text-sortio-primary" size={14} />
            <span className="text-xs font-medium uppercase tracking-wider text-sortio-primary">Enterprise Ready</span>
          </div>
        </div>
        <h2 className="text-4xl font-semibold mb-5 tracking-tight text-apple-50">
          Centralized <span className="apple-gradient-text">Deployment & Administration</span>
        </h2>
        <p className="text-apple-300 text-lg mx-auto font-light max-w-2xl mb-10">
          Deploy and manage Sortio across your entire organization with powerful administrative tools
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
        <EnterpriseFeatureCard 
          icon={Server}
          title="Management Console"
          description="Centralized dashboard for deploying and monitoring organization rules across all users"
        />
        
        <EnterpriseFeatureCard 
          icon={Users}
          title="Role-Based Permissions"
          description="Granular control over who can create, edit, and deploy organization rules"
        />
        
        <EnterpriseFeatureCard 
          icon={Clipboard}
          title="Audit Logging"
          description="Comprehensive logs of all actions for compliance purposes and troubleshooting"
        />
        
        <EnterpriseFeatureCard 
          icon={Network}
          title="Directory Integration"
          description="Seamless integration with Active Directory/LDAP for user management"
        />
      </div>
      
      <div className="bg-apple-700/40 p-8 rounded-3xl border border-apple-600/30 mb-10 relative overflow-hidden">
        {/* Gradient background */}
        <div className="absolute inset-0 bg-gradient-to-br from-sortio-primary/5 to-sortio-secondary/5 opacity-20"></div>
        <div className="relative z-10">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
            <div>
              <h3 className="text-2xl font-medium mb-3">Cross-Platform Support</h3>
              <p className="text-apple-300 max-w-2xl">Deploy Sortio Business across all major platforms in your organization with a consistent experience</p>
            </div>
            <div className="hidden md:flex items-center mt-4 md:mt-0">
              <Globe className="text-sortio-primary h-12 w-12" />
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <div className="bg-apple-800/40 p-5 rounded-2xl border border-apple-700/50 hover:border-sortio-primary/30 transition-all hover:translate-y-[-5px] duration-300 group">
              <div className="flex items-center mb-3">
                <Monitor className="text-sortio-primary mr-3 h-6 w-6 group-hover:text-sortio-secondary transition-colors" />
                <h4 className="text-lg font-medium text-apple-50">Windows</h4>
              </div>
              <p className="text-apple-300 text-sm">Full support for Windows 10/11 environments with Active Directory integration</p>
            </div>
            
            <div className="bg-apple-800/40 p-5 rounded-2xl border border-apple-700/50 hover:border-sortio-primary/30 transition-all hover:translate-y-[-5px] duration-300 group">
              <div className="flex items-center mb-3">
                <Laptop className="text-sortio-primary mr-3 h-6 w-6 group-hover:text-sortio-secondary transition-colors" />
                <h4 className="text-lg font-medium text-apple-50">macOS</h4>
              </div>
              <p className="text-apple-300 text-sm">Native macOS app with full Apple Silicon support for maximum performance</p>
            </div>
            
            <div className="bg-apple-800/40 p-5 rounded-2xl border border-apple-700/50 hover:border-sortio-primary/30 transition-all hover:translate-y-[-5px] duration-300 group">
              <div className="flex items-center mb-3">
                <HardDrive className="text-sortio-primary mr-3 h-6 w-6 group-hover:text-sortio-secondary transition-colors" />
                <h4 className="text-lg font-medium text-apple-50">Linux</h4>
              </div>
              <p className="text-apple-300 text-sm">Enterprise Linux support for server environments and developer workstations</p>
            </div>
            
            <div className="bg-apple-800/40 p-5 rounded-2xl border border-apple-700/50 hover:border-sortio-primary/30 transition-all hover:translate-y-[-5px] duration-300 group">
              <div className="flex items-center mb-3">
                <Network className="text-sortio-primary mr-3 h-6 w-6 group-hover:text-sortio-secondary transition-colors" />
                <h4 className="text-lg font-medium text-apple-50">Web Access</h4>
              </div>
              <p className="text-apple-300 text-sm">Secure browser-based access for remote work and cross-platform compatibility</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EnterpriseFeatures;