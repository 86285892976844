import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const Breadcrumbs = ({ category, articleTitle, items }) => {
  // If items prop is provided, use it for custom breadcrumbs
  if (items) {
    return (
      <nav aria-label="Breadcrumb" className="mb-6">
        <ol className="flex items-center flex-wrap text-sm">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <li className="mx-2 text-apple-300">
                  <ChevronRight size={14} />
                </li>
              )}
              <li>
                {index === items.length - 1 ? (
                  <span className="text-apple-300">{item.label}</span>
                ) : (
                  <Link 
                    to={item.path} 
                    className="text-apple-100 hover:text-sortio-primary transition-colors"
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            </React.Fragment>
          ))}
        </ol>
      </nav>
    );
  }
  
  // Original implementation for articles
  return (
    <nav className="text-sm mb-4">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="text-blue-400 hover:underline">Home</Link>
          {category || articleTitle ? <ChevronRight size={16} className="mx-2" /> : null}
        </li>
        <li className="flex items-center">
          <Link to="/articles" className="text-blue-400 hover:underline">Articles</Link>
          {category || articleTitle ? <ChevronRight size={16} className="mx-2" /> : null}
        </li>
        {category && (
          <li className="flex items-center">
            <span className="text-blue-400">{category}</span>
          </li>
        )}
        {articleTitle && (
          <>
            <ChevronRight size={16} className="mx-2" />
            <li className="text-gray-400">{articleTitle}</li>
          </>
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
