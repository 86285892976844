import React from 'react';
import { ChevronRight, ArrowRight } from 'lucide-react';

const BusinessHero = () => {
  return (
    <section className="relative min-h-[80vh] flex items-center text-apple-50 overflow-hidden bg-black pt-[120px]">
      {/* Gradient Orbs - Background Effect */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-[10%] left-[15%] w-[35vw] h-[35vw] rounded-full bg-sortio-secondary/20 blur-[120px] animate-float-slow" />
        <div className="absolute bottom-[10%] right-[15%] w-[40vw] h-[40vw] rounded-full bg-sortio-primary/20 blur-[150px] animate-float" />
      </div>
      
      {/* Content Container */}
      <div className="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-20 mt-10 md:mt-0">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
          {/* Left Column - Content */}
          <div className="flex flex-col">
            <h1 className="text-5xl md:text-6xl lg:text-7xl font-medium tracking-tight mb-6 leading-tight">
              <span className="apple-gradient-text">Sortio</span> for Business
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-apple-100 font-light max-w-md leading-relaxed">
              Enterprise-grade file organization powered by AI to transform how your organization manages documents
            </p>
            <div className="flex flex-col sm:flex-row gap-4 mt-2">
              <a 
                href="#waitlist"
                className="apple-button text-base px-8 py-4 flex items-center justify-center"
              >
                Join Waitlist <ArrowRight className="ml-2" size={18} />
              </a>
              <a 
                href="#overview"
                className="text-apple-50 hover:text-sortio-primary transition-all px-6 py-3 flex items-center justify-center border border-apple-300/30 rounded-full hover:border-sortio-primary"
              >
                Learn More <ChevronRight className="ml-1" size={18} />
              </a>
            </div>
          </div>
          
          {/* Right Column - Enterprise Visual */}
          <div className="relative flex justify-center md:justify-end items-center">
            <div className="w-full max-w-2xl">
              <div className="relative z-10 transform transition-all duration-500 bg-apple-800/30 backdrop-blur-md rounded-xl border border-apple-700/50 p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="p-4 bg-apple-700/50 rounded-lg border border-apple-600/30 flex flex-col items-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-sortio-secondary mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                    <span className="font-medium">Enterprise Security</span>
                  </div>
                  <div className="p-4 bg-apple-700/50 rounded-lg border border-apple-600/30 flex flex-col items-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-sortio-primary mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                    </svg>
                    <span className="font-medium">Admin Controls</span>
                  </div>
                  <div className="p-4 bg-apple-700/50 rounded-lg border border-apple-600/30 flex flex-col items-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-sortio-primary mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                    <span className="font-medium">AI Power</span>
                  </div>
                  <div className="p-4 bg-apple-700/50 rounded-lg border border-apple-600/30 flex flex-col items-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-sortio-secondary mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                    </svg>
                    <span className="font-medium">Cloud Integration</span>
                  </div>
                </div>
              </div>
              
              {/* Glow effect */}
              <div className="absolute inset-0 -z-10 bg-sortio-secondary/20 rounded-full blur-[60px] opacity-70 animate-pulse-slow" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessHero;