import React from 'react';
import { Shield, Lock, Brain, ArrowRight } from 'lucide-react';
import AnimatedBackground from './AnimatedBackground';

const SecurityFeature = ({ icon: Icon, title, description, color }) => (
  <div className="apple-panel backdrop-blur-xl p-8 rounded-3xl relative overflow-hidden group transition-all duration-500 hover:translate-y-[-5px]">
    {/* Colored gradient orb in background */}
    <div className={`absolute -right-20 -top-20 w-40 h-40 rounded-full ${color} opacity-10 blur-3xl group-hover:opacity-20 transition-opacity duration-500`}></div>
    
    {/* Icon with colored background */}
    <div className={`inline-flex items-center justify-center w-12 h-12 rounded-2xl ${color} bg-opacity-20 mb-6`}>
      <Icon className={`${color.replace('bg-', 'text-')} opacity-90`} size={24} />
    </div>
    
    <h3 className="text-xl font-medium mb-3 text-apple-50">{title}</h3>
    <p className="text-apple-300 text-base leading-relaxed">{description}</p>
    
    {/* Subtle line separator */}
    <div className="h-px w-16 bg-gradient-to-r from-transparent via-apple-600/40 to-transparent my-6"></div>
    
  </div>
);

const SecuritySection = () => (
  <section id="security" className="py-36 relative overflow-hidden" style={{ backgroundColor: "#121212", backgroundSize: "30px 30px", backgroundImage: "radial-gradient(circle, rgba(255,255,255,0.03) 1px, transparent 1px)" }}>
    {/* Section separator */}
    <div className="absolute top-0 inset-x-0 h-px bg-gradient-to-r from-transparent via-apple-600/40 to-transparent"></div>
    {/* Section border */}
    <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-apple-600/40 to-transparent z-0" />
    
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Section header */}
      <div className="text-center mb-20">
        <div className="inline-block mb-4">
          <div className="flex items-center justify-center p-1.5 rounded-full bg-sortio-primary/10 px-4 py-1.5">
            <Shield className="mr-2 text-sortio-primary" size={14} />
            <span className="text-xs font-medium uppercase tracking-wider text-sortio-primary">Security First</span>
          </div>
        </div>
        <h2 className="text-4xl md:text-5xl font-semibold mb-5 tracking-tight text-apple-50">
          Privacy & Security <span className="apple-gradient-text">by Design</span>
        </h2>
        <p className="text-apple-300 text-lg max-w-2xl mx-auto font-light">
          We built Sortio with your privacy and security as our top priorities
        </p>
      </div>
      
      {/* Features grid */}
      <div className="grid md:grid-cols-3 gap-8">
        <SecurityFeature
          icon={Shield}
          title="End-to-End Encryption"
          description="Your file metadata is encrypted end-to-end, ensuring that only you can access the details of your sorted files."
          color="bg-sortio-primary"
        />
        <SecurityFeature
          icon={Lock}
          title="Secure Authentication"
          description="We use Auth0, a leader in identity management, to ensure secure login and protect your account from unauthorized access."
          color="bg-sortio-secondary"
        />
        <SecurityFeature
          icon={Brain}
          title="Privacy-Preserving AI"
          description="Our AI-powered sorting algorithms work with encrypted file metadata, ensuring your content remains private while delivering smart organization."
          color="bg-sortio-accent"
        />
      </div>
      
      {/* Security commitment */}
      <div className="mt-20 text-center">
        <div className="apple-panel max-w-3xl mx-auto p-6 rounded-2xl">
          <p className="text-apple-200 text-sm leading-relaxed">
            At Sortio, we're committed to maintaining the highest standards of security and privacy. 
            We never access your file contents, and your data remains safe and secure.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default SecuritySection;