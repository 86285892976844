import React from 'react';
import { Folder, Shield, Zap } from 'lucide-react';

const FeaturePoint = ({ icon: Icon, title, description, color = "text-sortio-primary bg-sortio-primary/20" }) => (
  <div className="flex flex-col items-start">
    <div className={`w-14 h-14 rounded-2xl ${color} flex items-center justify-center mb-4`}>
      <Icon className="h-7 w-7" />
    </div>
    <h4 className="text-lg font-medium mb-2 text-apple-50">{title}</h4>
    <p className="text-apple-300">{description}</p>
  </div>
);

const BusinessOverview = () => {
  return (
    <section id="overview" className="mb-16">
      {/* Section header */}
      <div className="text-center mb-10">
        <h2 className="text-4xl font-semibold mb-5 tracking-tight text-apple-50">
          Organize Your <span className="apple-gradient-text">Enterprise Documents</span> with AI
        </h2>
        <p className="text-xl text-apple-300 max-w-3xl mx-auto leading-relaxed font-light">
          Sortio Business is an intelligent filesystem agent that helps organizations organize, 
          find, and extract insights from their documents using natural language — without migrating 
          to a new platform.
        </p>
      </div>

      <div className="bg-apple-700/30 backdrop-blur-md p-10 rounded-3xl border border-apple-600/30 mb-8 relative overflow-hidden">
        {/* Subtle gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-br from-sortio-primary/5 to-sortio-secondary/5 opacity-30"></div>
        
        <div className="relative z-10">
          <h3 className="text-2xl font-medium mb-8 text-apple-50 text-center">Why Sortio for Business?</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            <FeaturePoint 
              icon={Folder}
              title="Zero Migration"
              description="Works with existing file structures, no need to move your data to a new platform"
              color="text-sortio-primary bg-sortio-primary/20"
            />
            
            <FeaturePoint 
              icon={Shield}
              title="Enterprise Security"
              description="Advanced security controls with on-premises deployment options for regulated industries"
              color="text-sortio-secondary bg-sortio-secondary/20"
            />
            
            <FeaturePoint 
              icon={Zap}
              title="Centralized Control"
              description="Deploy and manage organization-wide with powerful administration tools"
              color="text-sortio-accent bg-sortio-accent/20"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessOverview;