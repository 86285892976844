import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Clock, ArrowRight, SearchIcon, Calendar, Filter } from 'lucide-react';
import config from '../config';
import Breadcrumbs from '../components/Breadcrumbs';

const ArticlesPage = () => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    fetchArticlesAndCategories();
  }, []);

  const fetchArticlesAndCategories = async () => {
    try {
      setLoading(true);
      const [articlesResponse, categoriesResponse] = await Promise.all([
        fetch(`${config.apiUrl}/articles/`),
        fetch(`${config.apiUrl}/categories/`)
      ]);

      if (!articlesResponse.ok || !categoriesResponse.ok) {
        throw new Error('Failed to fetch data');
      }

      const articlesData = await articlesResponse.json();
      const categoriesData = await categoriesResponse.json();

      setArticles(articlesData.articles || []);
      setCategories(categoriesData.categories || []);
      setError(null);
    } catch (err) {
      console.error(err);
      setError('Unable to load articles. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const stripHtml = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const filteredArticles = articles
    .filter(article => {
      const matchesSearch = searchTerm === '' || 
        article.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
        stripHtml(article.excerpt).toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesCategory = selectedCategory === null || 
        article.category_name === selectedCategory;
      
      return matchesSearch && matchesCategory;
    });

  // Generate placeholder articles if none exist
  const placeholderArticles = [
    {
      id: 'placeholder-1',
      title: 'How AI is Revolutionizing File Organization',
      excerpt: 'Discover how artificial intelligence is transforming the way we organize and manage digital files, making it easier than ever to maintain a clean and structured digital workspace.',
      created_at: '2023-09-15T10:00:00Z',
      category_slug: 'ai-technology',
      category_name: 'AI Technology',
      slug: 'how-ai-revolutionizing-file-organization',
      image_url: 'https://via.placeholder.com/600x400'
    },
    {
      id: 'placeholder-2',
      title: 'Getting Started with Sortio: A Beginner\'s Guide',
      excerpt: 'Learn how to set up and use Sortio effectively with this comprehensive beginner\'s guide. From installation to advanced features, we\'ve got you covered.',
      created_at: '2023-09-10T14:30:00Z',
      category_slug: 'tutorials',
      category_name: 'Tutorials',
      slug: 'getting-started-with-sortio',
      image_url: 'https://via.placeholder.com/600x400'
    },
    {
      id: 'placeholder-3',
      title: '5 Tips for Maintaining a Clean Digital Workspace',
      excerpt: 'Follow these five essential tips to keep your digital files organized and accessible, improving productivity and reducing stress in your daily workflow.',
      created_at: '2023-09-05T09:15:00Z',
      category_slug: 'productivity',
      category_name: 'Productivity',
      slug: '5-tips-maintaining-clean-digital-workspace',
      image_url: 'https://via.placeholder.com/600x400'
    },
    {
      id: 'placeholder-4',
      title: 'Understanding Metadata: The Key to Smart File Organization',
      excerpt: 'Explore the importance of metadata in modern file organization systems and how AI tools like Sortio leverage this information to create intelligent organization structures.',
      created_at: '2023-08-28T11:45:00Z',
      category_slug: 'technical',
      category_name: 'Technical',
      slug: 'understanding-metadata-key-smart-file-organization',
      image_url: 'https://via.placeholder.com/600x400'
    },
  ];

  // Generate placeholder categories if none exist
  const placeholderCategories = [
    { id: 'placeholder-cat-1', name: 'AI Technology', slug: 'ai-technology' },
    { id: 'placeholder-cat-2', name: 'Tutorials', slug: 'tutorials' },
    { id: 'placeholder-cat-3', name: 'Productivity', slug: 'productivity' },
    { id: 'placeholder-cat-4', name: 'Technical', slug: 'technical' },
  ];

  const displayArticles = articles.length > 0 ? filteredArticles : placeholderArticles;
  const displayCategories = categories.length > 0 ? categories : placeholderCategories;

  return (
    <div className="min-h-screen bg-black pt-24 pb-16">
      {/* Background blur orbs */}
      <div className="fixed -bottom-32 -right-32 w-96 h-96 bg-sortio-primary/10 rounded-full blur-3xl"></div>
      <div className="fixed -top-32 -left-32 w-96 h-96 bg-sortio-secondary/10 rounded-full blur-3xl"></div>
      
      <div className="container mx-auto px-6 relative z-10">
        <Helmet>
          <title>Articles - Sortio</title>
          <meta name="description" content="Explore insightful articles on AI-powered file organization and more." />
          <link rel="canonical" href="https://www.getsortio.com/articles" />
          <meta property="og:title" content="Articles - Sortio" />
          <meta property="og:description" content="Explore insightful articles on AI-powered file organization and more." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.getsortio.com/articles" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Articles - Sortio" />
          <meta name="twitter:description" content="Explore insightful articles on AI-powered file organization and more." />
        </Helmet>

        <Breadcrumbs category="Articles" articleTitle="" />

        {/* Page title */}
        <div className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-apple-50 to-sortio-primary">Articles & Resources</span>
          </h1>
          <p className="text-apple-300 max-w-2xl mx-auto">
            Discover insights, tips, and guides on AI-powered file organization and digital productivity
          </p>
        </div>

        {/* Search and filter */}
        <div className="apple-panel p-6 mb-12">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="relative flex-grow">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-apple-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-2 border border-apple-700 rounded-xl bg-apple-800/50 text-apple-100 placeholder-apple-400 focus:outline-none focus:ring-2 focus:ring-sortio-primary focus:border-transparent transition-all"
                placeholder="Search articles..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="relative min-w-[200px]">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Filter className="h-5 w-5 text-apple-400" />
              </div>
              <select 
                className="block w-full pl-10 pr-10 py-2 border border-apple-700 rounded-xl bg-apple-800/50 text-apple-100 appearance-none focus:outline-none focus:ring-2 focus:ring-sortio-primary focus:border-transparent transition-all"
                value={selectedCategory || ''}
                onChange={(e) => setSelectedCategory(e.target.value === '' ? null : e.target.value)}
              >
                <option value="">All Categories</option>
                {displayCategories.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-apple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* Categories pills */}
        <div className="mb-12">
          <div className="flex flex-wrap gap-2">
            <button 
              onClick={() => setSelectedCategory(null)}
              className={`px-4 py-2 rounded-full transition-all ${selectedCategory === null 
                ? 'bg-sortio-primary text-black font-medium' 
                : 'bg-apple-800/50 text-apple-300 hover:bg-apple-700/70'}`}
            >
              All
            </button>
            
            {displayCategories.map((category) => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.name)}
                className={`px-4 py-2 rounded-full transition-all ${selectedCategory === category.name 
                  ? 'bg-sortio-primary text-black font-medium' 
                  : 'bg-apple-800/50 text-apple-300 hover:bg-apple-700/70'}`}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center">
            <div className="apple-panel p-8 text-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-sortio-primary mx-auto mb-4"></div>
              <p className="text-apple-300">Loading articles...</p>
            </div>
          </div>
        ) : error ? (
          <div className="apple-panel p-8 text-center border-red-500/20">
            <p className="text-red-400">{error}</p>
          </div>
        ) : (
          <div className="grid md:grid-cols-2 gap-8">
            {displayArticles.map((article) => (
              <article key={article.id} className="apple-panel group p-6 relative overflow-hidden">
                <div className="absolute -bottom-8 -right-8 w-32 h-32 bg-sortio-primary/10 rounded-full blur-2xl opacity-50 group-hover:opacity-100 transition-opacity duration-500"></div>
                
                <h2 className="text-2xl font-semibold mb-3 group-hover:text-sortio-primary transition-colors">
                  <Link to={`/articles/${article.category_slug}/${article.slug}`}>
                    {article.title}
                  </Link>
                </h2>
                
                <div className="flex items-center space-x-4 mb-4 text-sm text-apple-400">
                  <span className="flex items-center">
                    <Calendar className="w-4 h-4 mr-1" />
                    {formatDate(article.created_at)}
                  </span>
                  <span className="flex items-center">
                    <Filter className="w-4 h-4 mr-1" />
                    {article.category_name}
                  </span>
                </div>
                
                <p className="text-apple-300 mb-5">
                  {stripHtml(article.excerpt)}
                </p>
                
                <Link 
                  to={`/articles/${article.category_slug}/${article.slug}`}
                  className="inline-flex items-center text-sortio-primary hover:text-sortio-primary/80 transition-colors group-hover:underline"
                >
                  Read more
                  <ArrowRight className="ml-2 w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                </Link>
              </article>
            ))}
          </div>
        )}

        {displayArticles.length === 0 && !loading && (
          <div className="apple-panel p-8 text-center">
            <p className="text-apple-300">No articles found matching your criteria.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticlesPage;