import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-scroll';

const TableOfContents = ({ tableOfContents, sections }) => {
  const [activeSection, setActiveSection] = useState('');
  
  // Use sections prop if provided, otherwise use tableOfContents
  const items = sections || tableOfContents;

  const getActiveSection = useCallback(() => {
    const sectionElements = items.map(item => document.getElementById(item.id));
    const scrollPosition = window.scrollY;

    for (let i = sectionElements.length - 1; i >= 0; i--) {
      const section = sectionElements[i];
      if (section && section.offsetTop <= scrollPosition + 100) {
        return items[i].id;
      }
    }

    return items[0]?.id || '';
  }, [items]);

  useEffect(() => {
    const handleScroll = () => {
      const currentSection = getActiveSection();
      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: '-20% 0% -80% 0%', threshold: 0.1 }
    );

    items.forEach((item) => {
      const element = document.getElementById(item.id);
      if (element) observer.observe(element);
    });

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial active section

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, [items, activeSection, getActiveSection]);

  return (
    <nav className="sticky top-24 max-h-[calc(100vh-6rem)] overflow-auto">
      <div className="bg-apple-700/30 backdrop-blur-sm p-4 rounded-apple border border-apple-600/20">
        <h2 className="text-lg font-medium text-apple-50 mb-4">Table of Contents</h2>
        <ul className="space-y-2">
          {items.map((item) => (
            <li key={item.id} className="ml-1">
              <Link
                to={item.id}
                spy={true}
                smooth={true}
                duration={500}
                offset={-100}
                className={`block text-sm py-1 border-l-2 pl-3 transition-colors cursor-pointer ${
                  activeSection === item.id
                    ? 'border-sortio-primary text-sortio-primary font-medium'
                    : 'border-apple-600/30 text-apple-200 hover:text-apple-50 hover:border-apple-400'
                }`}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default TableOfContents;