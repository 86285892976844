import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FileText, Lock, ShieldCheck, AlertTriangle, ChevronLeft, Info, Zap, FileWarning } from 'lucide-react';

const TermsAndConditionsPage = () => {
  return (
    <div className="min-h-screen bg-black text-apple-100 py-24">
      <Helmet>
        <title>Terms and Conditions - Sortio</title>
        <meta name="description" content="Terms and Conditions for using Sortio, the AI-powered file organization app." />
      </Helmet>
      
      {/* Background gradient */}
      <div className="absolute top-0 inset-x-0 h-[500px] bg-gradient-to-b from-apple-700/10 to-transparent"></div>
      <div className="absolute -top-[600px] -left-[300px] w-[1000px] h-[1000px] bg-sortio-secondary/5 rounded-full blur-3xl"></div>
      
      <div className="container mx-auto px-6 max-w-4xl relative z-10">
        {/* Back button */}
        <Link to="/" className="inline-flex items-center text-apple-300 hover:text-sortio-primary mb-12 transition-colors group">
          <ChevronLeft className="w-4 h-4 mr-1 group-hover:-translate-x-1 transition-transform" />
          <span>Back to Home</span>
        </Link>
        
        {/* Header */}
        <div className="mb-16 flex flex-col items-center">
          <div className="w-14 h-14 rounded-full bg-apple-700/60 backdrop-blur-sm flex items-center justify-center mb-6 border border-apple-600/30">
            <FileText className="w-6 h-6 text-sortio-primary" />
          </div>
          <h1 className="text-4xl lg:text-5xl font-bold text-center">
            Terms and <span className="apple-gradient-text">Conditions</span>
          </h1>
          <p className="text-apple-300 mt-4 text-center max-w-xl">
            Please read these terms carefully before using Sortio.
          </p>
        </div>
        
        {/* Content */}
        <div className="apple-panel p-8 md:p-12 rounded-apple backdrop-blur-sm mb-12">
          <div className="space-y-12">
            {/* Agreement Notice */}
            <div className="p-4 rounded-lg bg-sortio-primary/10 border border-sortio-primary/30">
              <p className="text-apple-200 text-sm font-medium">
                BY DOWNLOADING, INSTALLING, OR USING THE SORTIO APPLICATION, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE, DO NOT DOWNLOAD, INSTALL, OR USE THE APPLICATION.
              </p>
            </div>
            
            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">1</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Introduction</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                Welcome to Sortio. These Terms and Conditions govern your use of our application and services. By using Sortio, you agree to these terms, which constitute a legally binding agreement between you and Sortio.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">2</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Data Collection and Privacy</h2>
              </div>
              <div className="pl-11">
                <p className="text-apple-300 leading-relaxed mb-3">
                  At Sortio, we prioritize your privacy and data security:
                </p>
                <ul className="space-y-2">
                  {[
                    "We do not store or retain any of your personal files or folders.",
                    "Our app only processes filenames, not the contents of your files.",
                    "We do not use any of your data for training our AI models.",
                    "All data processed through our app is encrypted for your security."
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <Lock className="w-5 h-5 text-sortio-secondary mr-3 mt-0.5 flex-shrink-0" />
                      <span className="text-apple-300">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">3</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Use of the Application</h2>
              </div>
              <div className="pl-11 space-y-4">
                <p className="text-apple-300 leading-relaxed">
                  Sortio is designed to help organize your files using AI-powered algorithms. You are responsible for any changes made to your file system using our app. By using Sortio, you confirm that you have the legal right to access and modify all files you process with our application.
                </p>
                <div className="flex items-start p-4 rounded-lg bg-apple-600/20 border border-apple-600/30">
                  <Info className="w-5 h-5 text-sortio-primary mr-3 mt-0.5 flex-shrink-0" />
                  <p className="text-apple-300 text-sm">
                    We strongly recommend reviewing the changes made by the application before confirming any file operations and maintaining regular backups of your data.
                  </p>
                </div>
                <p className="text-apple-300 leading-relaxed">
                  You agree not to use Sortio for any illegal or unauthorized purpose, including but not limited to organizing files that infringe on intellectual property rights, contain malicious code, or violate applicable laws.
                </p>
              </div>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">4</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Intellectual Property</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                Sortio and its original content, features, and functionality are owned by us and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">5</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Disclaimer of Warranties</h2>
              </div>
              <div className="pl-11 space-y-4">
                <p className="text-apple-300 leading-relaxed">
                  SORTIO IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. 
                </p>
                <p className="text-apple-300 leading-relaxed">
                  WE DO NOT WARRANT THAT THE APPLICATION WILL BE ERROR-FREE, UNINTERRUPTED, FREE FROM HARMFUL COMPONENTS, OR THAT DEFECTS WILL BE CORRECTED. WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE ACCURACY, RELIABILITY, OR PERFORMANCE OF THE APPLICATION OR THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE APPLICATION.
                </p>
                <div className="flex items-start p-4 rounded-lg bg-apple-600/20 border border-apple-600/30">
                  <AlertTriangle className="w-5 h-5 text-sortio-accent mr-3 mt-0.5 flex-shrink-0" />
                  <p className="text-apple-300 text-sm">
                    Always maintain backups of important files before using any file organization software. Sortio is not responsible for any lost, corrupted, or inaccessible files.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">6</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Limitation of Liability</h2>
              </div>
              <div className="pl-11 space-y-4">
                <p className="text-apple-300 leading-relaxed">
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL SORTIO, ITS DIRECTORS, EMPLOYEES, PARTNERS, AGENTS, SUPPLIERS, OR AFFILIATES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM:
                </p>
                <ul className="space-y-2">
                  {[
                    "Your access to or use of or inability to access or use the application;",
                    "Any changes, movement, deletion, or reorganization of your files by the application;",
                    "Unauthorized access, use, or alteration of your data;",
                    "Any errors or omissions in the application's functionality;",
                    "System failures or data loss, including those resulting from the application's operations;",
                    "Any damages whatsoever, even if foreseeable, resulting from use of the application."
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <AlertTriangle className="w-5 h-5 text-sortio-accent mr-3 mt-0.5 flex-shrink-0" />
                      <span className="text-apple-300">{item}</span>
                    </li>
                  ))}
                </ul>
                <p className="text-apple-300 leading-relaxed">
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>
              </div>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">7</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Indemnification</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                You agree to defend, indemnify, and hold harmless Sortio, its owners, operators, officers, employees, and agents, from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Application, including, but not limited to, any use of the Application's content, services, and products other than as expressly authorized in these Terms.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">8</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Governing Law and Jurisdiction</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Sortio operates, without regard to its conflict of law principles. By using the Application, you consent to the exclusive jurisdiction and venue of the courts in the relevant jurisdiction for any disputes arising out of or related to these Terms or your use of the Application.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">9</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Changes to Terms</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                We reserve the right to modify or replace these Terms at any time at our sole discretion. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Application after those revisions become effective, you agree to be bound by the revised terms.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">10</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Severability</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
              </p>
            </section>

            <section>
              <div className="flex items-center mb-4">
                <span className="w-8 h-8 rounded-full bg-sortio-primary/20 flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-sortio-primary font-medium text-sm">11</span>
                </span>
                <h2 className="text-xl font-medium text-apple-50">Contact Us</h2>
              </div>
              <p className="text-apple-300 leading-relaxed pl-11">
                If you have any questions about these Terms, please{' '}
                <Link to="/#contact" className="text-sortio-primary hover:text-sortio-primary/80 transition-colors">
                  contact us
                </Link>.
              </p>
            </section>
          </div>
          
          {/* Last updated */}
          <div className="mt-12 pt-6 border-t border-apple-700/20">
            <p className="text-sm text-apple-400">Last Updated: October 12, 2024</p>
          </div>
        </div>
        
        {/* Back to top button */}
        <div className="flex justify-center mb-8">
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="inline-flex items-center px-4 py-2 rounded-full bg-apple-700/40 border border-apple-600/30 text-sm text-apple-300 hover:text-sortio-primary transition-colors"
          >
            <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="m18 15-6-6-6 6"/>
            </svg>
            <span>Back to top</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;